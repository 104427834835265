import { TOfferForBuyer } from "types";
import { RequestPromise, apiRequest } from "utils/api";

export const getOffersForBuyerByApplicationId = (
  id: TGetOffersForBuyerByApplicationIdRequest
): RequestPromise<TGetOffersForBuyerByApplicationIdResponse> => {
  return apiRequest.get(`/offers-for-buyers/applications/${id}`);
};

export type TGetOffersForBuyerByApplicationIdRequest = number;

export type TGetOffersForBuyerByApplicationIdResponse = TOfferForBuyer[];
