import { TLicense } from "types";
import { apiRequest, RequestPromise } from "utils/api";

export * from "./id";
export * from "./add";
export * from "./update";
export * from "./delete";
export * from "./type";

export const getLicenses = (): RequestPromise<TGetLicensesResponse> => {
  return apiRequest.get(`/license/`);
};

export type TGetLicensesResponse = TLicense[];
