import { TLicenseType } from "types";
import { RequestPromise, apiRequest } from "utils/api";

export const addLicenseType = (
  data: TAddLicenseTypeRequest
): RequestPromise<TAddLicenseTypeResponse> => {
  return apiRequest.post(`/license/type/add`, data);
};

export type TAddLicenseTypeRequest = Omit<TLicenseType, "license_type_id">;

export type TAddLicenseTypeResponse = TLicenseType;
