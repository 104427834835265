import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { TOffersForSellerGetState } from "./types";

import { RequestError, TOfferForSeller } from "types";
import { DEFAULT_PAGE_SIZE } from "constants/pagination";

const initialState: TOffersForSellerGetState = {
  isLoading: false,
  error: null,
  offersForSeller: undefined,
  totalElements: undefined,
  page: 1,
  size: DEFAULT_PAGE_SIZE,
  search: undefined,
  asc: false,
  sort: "createTs"
};

export const OffersForSellerGetSlice = createSlice({
  name: "offersForSeller/get",
  initialState,
  reducers: {
    clearState: () => initialState,
    setOffersForSeller: (
      state,
      { payload }: PayloadAction<TOfferForSeller[]>
    ) => {
      state.offersForSeller = payload;
    },
    setTotalElements: (state, { payload }: PayloadAction<number>) => {
      state.totalElements = payload;
    },
    setPage: (state, { payload }: PayloadAction<number>) => {
      state.page = payload;
    },
    setSize: (state, { payload }: PayloadAction<number>) => {
      state.size = payload;
    },
    setSearch: (state, { payload }: PayloadAction<string | undefined>) => {
      state.search = payload;
    },
    setAsc: (state, { payload }: PayloadAction<boolean>) => {
      state.asc = payload;
    },
    setSort: (state, { payload }: PayloadAction<string>) => {
      state.sort = payload;
    },
    setLoading: (state, { payload }: PayloadAction<boolean>) => {
      state.isLoading = payload;
    },
    setError: (state, { payload }: PayloadAction<RequestError | null>) => {
      state.error = payload;
    }
  }
});

export const offersForSellerGetActions = OffersForSellerGetSlice.actions;
export const offersForSellerGetReducer = OffersForSellerGetSlice.reducer;

export * as offersForSellerGetSelectors from "./selectors";
