import { TLocation } from "types";
import { RequestPromise, apiRequest } from "utils/api";

export const updateLocation = (
  data: TUpdateLocationRequest
): RequestPromise<TUpdateLocationResponse> => {
  return apiRequest.post(`/location/update`, data);
};

export type TUpdateLocationRequest = TLocation;

export type TUpdateLocationResponse = TLocation;
