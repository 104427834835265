import { TAccount } from "types";
import { RequestPromise, apiRequest } from "utils/api";

export const getAccountsByEntityId = (
  id: TByEntityIdAccountRequest
): RequestPromise<TByEntityIdAccountResponse> => {
  return apiRequest.get(`/accounts/entity/${id}`);
};

export type TByEntityIdAccountRequest = number;

export type TByEntityIdAccountResponse = TAccount[];
