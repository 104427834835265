import { RequestPromise, apiRequest } from "utils/api";

export const getFileById = (
  id: TGetFileByIdRequest
): RequestPromise<TGetFileByIdResponse> => {
  return apiRequest.get(`/files/${id}`, {
    responseType: "blob"
  });
};

export type TGetFileByIdRequest = number; //file_meta_id

export type TGetFileByIdResponse = Blob;
