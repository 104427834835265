import { TProject } from "types";
import { RequestPromise, apiRequest } from "utils/api";

export const addProject = (
  data: TAddProjectRequest
): RequestPromise<TAddProjectResponse> => {
  return apiRequest.post(`/project/add`, data);
};

export type TAddProjectRequest = Omit<TProject, "project_id">;

export type TAddProjectResponse = TProject;
