import { createAsyncThunk } from "@reduxjs/toolkit";
import { ApplicationForSellerByIdActions } from "./byId";
import { applicationsForSellerGetActions } from "./get";
import { applicationForSellerBestPricesByIdActions } from "./bestPricesById";

export const clearApplicationsForSeller = createAsyncThunk<void, void>(
  "applicationForSeller/clearApplicationsForSeller",
  (payload, { dispatch, rejectWithValue }) => {
    dispatch(ApplicationForSellerByIdActions.clearState());
    dispatch(applicationForSellerBestPricesByIdActions.clearState());
    dispatch(applicationsForSellerGetActions.clearState());
  }
);
