import { TLocation } from "types";
import { RequestPromise, apiRequest } from "utils/api";

export const addLocation = (
  data: TAddLocationRequest
): RequestPromise<TAddLocationResponse> => {
  return apiRequest.post(`/location/add`, data);
};

export type TAddLocationRequest = Omit<TLocation, "location_id">;

export type TAddLocationResponse = TLocation;
