import React, { FC, useMemo } from "react";
import { ECabinetRole, TCabinet } from "types";
import { Button, Table } from "antd";
import { ColumnsType } from "antd/es/table";
import { DeleteOutlined, EditOutlined } from "@ant-design/icons";
import { cabinetRoleMapper } from "utils/mappers";

type TCabinetsTableProps = {
  cabinets?: TCabinet[];
  onDelete?: (id: number) => void;
  onEdit?: (id: number) => void;
  isDeleting?: boolean;
};

export const CabinetsTable: FC<TCabinetsTableProps> = ({
  cabinets,
  onDelete,
  onEdit,
  isDeleting
}) => {
  const columns: ColumnsType<TCabinet> = useMemo(
    () => [
      {
        title: "Имя",
        dataIndex: "name"
      },
      {
        title: "Сторона",
        dataIndex: "cabinetRole",
        render: (value: ECabinetRole) => cabinetRoleMapper[value] || value
      },
      {
        key: "edit",
        render: (value: TCabinet) => (
          <Button
            onClick={() => onEdit?.(value?.cabinet_id)}
            icon={<EditOutlined />}
          >
            Редактировать
          </Button>
        )
      },
      {
        key: "delete",
        render: (value: TCabinet) => (
          <Button
            onClick={() => onDelete?.(value?.cabinet_id)}
            icon={<DeleteOutlined />}
            danger
            loading={isDeleting}
          >
            Удалить
          </Button>
        )
      }
    ],
    [isDeleting, onDelete, onEdit]
  );

  return <Table dataSource={cabinets} columns={columns} pagination={false} />;
};
