import React, { useCallback, useEffect, useMemo, useState } from "react";
import {
  ApplicationsTable,
  EnlargedTag,
  ErrorAlert,
  OfferEditForm,
  OfferData,
  Files
} from "components/common/old";
import { useAppDispatch, useAppSelector } from "store/store";
import {
  Alert,
  Button,
  Dropdown,
  MenuProps,
  Space,
  Tag,
  Typography
} from "antd";
import { useNavigate, useParams } from "react-router-dom";
import { userSelectors } from "store/user";
import { CTABlock, Wrapper } from "./OfferForSeller.styles";
import { ECabinetRole, EFileType, TOfferForSellerRequest } from "types";
import { CloseOutlined, DownOutlined, EditOutlined } from "@ant-design/icons";
import { offerStatusIdMapper } from "utils/mappers";
import { getAccountsByCabinetId } from "store/accounts/byCabinet/thunk";
import { accountsByCabinetIdSelectors } from "store/accounts/byCabinet";
import { offerUpdateForSellerStatusOptions } from "constants/options";
import {
  offerForSellerByIdActions,
  offerForSellerByIdSelectors
} from "store/offersForSeller/byId";
import {
  offerForSellerUpdateActions,
  offerForSellerUpdateSelectors
} from "store/offersForSeller/update";
import {
  offerForSellerUpdateStatusActions,
  offerForSellerUpdateStatusSelectors
} from "store/offersForSeller/update_status";
import { getOfferForSellerById } from "store/offersForSeller/byId/thunk";
import { updateOfferForSeller } from "store/offersForSeller/update/thunk";
import { updateOfferForSellerStatus } from "store/offersForSeller/update_status/thunk";
import { getApplicationForSellerById } from "store/applicationsForSeller/byId/thunk";
import {
  ApplicationForSellerByIdActions,
  applicationForSellerByIdSelectors
} from "store/applicationsForSeller/byId";
import {
  filesByObjectActions,
  filesByObjectSelectors
} from "store/files/byObject";
import { getFilesByObject } from "store/files/byObject/thunk";
import { entitiesByCabinetIdSelectors } from "store/entities/byCabinetId";
import { adminSelectors } from "store/admin";
import { getEntitiesByCabinetId } from "store/entities/byCabinetId/thunk";

export const OfferForSeller = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const [isEditing, setIsEditing] = useState(false);

  const { user, currentRole, isAdmin } = useAppSelector(userSelectors.getState);
  const { offerForSeller } = useAppSelector(
    offerForSellerByIdSelectors.getState
  );
  const { isLoading: updateIsLoading, error: updateError } = useAppSelector(
    offerForSellerUpdateSelectors.getState
  );
  const { isLoading: statusIsLoading, error: statusError } = useAppSelector(
    offerForSellerUpdateStatusSelectors.getState
  );
  const { accounts } = useAppSelector(accountsByCabinetIdSelectors.getState);
  const { application } = useAppSelector(
    applicationForSellerByIdSelectors.getState
  );
  const { files } = useAppSelector(filesByObjectSelectors.getState);
  const { entities } = useAppSelector(entitiesByCabinetIdSelectors.getState);
  const { selectedCabinet, isAdminSeller } = useAppSelector(
    adminSelectors.getState
  );

  const { offer_id } = useParams<{ offer_id: string }>();

  const cabinet_id = useMemo(
    () => (isAdmin ? selectedCabinet : user?.cabinet?.cabinet_id),
    [isAdmin, selectedCabinet, user?.cabinet?.cabinet_id]
  );

  const statusOptions: MenuProps["items"] = useMemo(
    () =>
      offerForSeller
        ? (
            offerUpdateForSellerStatusOptions[offerForSeller?.status_id] || []
          ).map((status) => {
            const statusData = offerStatusIdMapper[status];
            return {
              key: status,
              label: (
                <Tag color={statusData?.color}>{statusData?.shortName}</Tag>
              )
            };
          })
        : [],
    [offerForSeller]
  );

  const canUpdate = useMemo(() => {
    const foundEntity = user?.entities?.find(
      (entity) =>
        offerForSeller?.seller_entity?.entity_id === entity?.entity?.entity_id
    );
    return (
      (foundEntity?.role_id && foundEntity?.role_id >= 2) ||
      (foundEntity?.entity?.cabinet_id === user?.cabinet?.cabinet_id &&
        user?.cabinet_role_id === 2) ||
      isAdmin
    );
  }, [
    isAdmin,
    offerForSeller?.seller_entity?.entity_id,
    user?.cabinet?.cabinet_id,
    user?.cabinet_role_id,
    user?.entities
  ]);

  const canChangeStatus = useMemo(
    () => !!statusOptions?.length && canUpdate,
    [canUpdate, statusOptions]
  );
  const canEditFiles = useMemo(() => canUpdate, [canUpdate]);

  const statusData = useMemo(
    () =>
      offerForSeller?.status_id
        ? offerStatusIdMapper[offerForSeller?.status_id]
        : undefined,
    [offerForSeller]
  );

  const entitiesForOffer = useMemo(
    () =>
      isAdmin
        ? entities
        : user?.entities
            ?.filter(
              (entity) =>
                entity?.role_id >= 2 ||
                (entity?.entity?.cabinet_id === user?.cabinet?.cabinet_id &&
                  user?.cabinet_role_id === 2)
            )
            .map((entity) => entity?.entity),
    [
      entities,
      isAdmin,
      user?.cabinet?.cabinet_id,
      user?.cabinet_role_id,
      user?.entities
    ]
  );

  const getOffer = useCallback(() => {
    dispatch(getOfferForSellerById(Number(offer_id)));
  }, [dispatch, offer_id]);

  const getAccounts = useCallback(() => {
    cabinet_id && dispatch(getAccountsByCabinetId(cabinet_id));
  }, [cabinet_id, dispatch]);

  const getEntities = useCallback(() => {
    cabinet_id && dispatch(getEntitiesByCabinetId(cabinet_id));
  }, [cabinet_id, dispatch]);

  const getApplication = useCallback(
    (application_id: number) => {
      return dispatch(getApplicationForSellerById(application_id));
    },
    [dispatch]
  );

  const onEdit = useCallback(() => {
    setIsEditing(true);
  }, []);
  const onCancelEdit = useCallback(() => {
    setIsEditing(false);
  }, []);

  const onUpdate = useCallback(
    (values: TOfferForSellerRequest) => {
      dispatch(updateOfferForSeller(values))
        .unwrap()
        .then(() => {
          onCancelEdit();
          getOffer();
        });
    },
    [dispatch, getOffer, onCancelEdit]
  );

  const onUpdateStatus = useCallback(
    ({ key }: { key: string }) => {
      offerForSeller &&
        dispatch(
          updateOfferForSellerStatus({
            offer_id: offerForSeller?.offer_id,
            new_status_id: Number(key)
          })
        )
          .unwrap()
          .then(() => {
            getOffer();
          });
    },
    [dispatch, getOffer, offerForSeller]
  );

  const getFiles = useCallback(
    (offer_id: number) => {
      dispatch(
        getFilesByObject({
          id: offer_id,
          type: EFileType.OFFER
        })
      );
    },
    [dispatch]
  );

  useEffect(() => {
    if (isAdmin ? !isAdminSeller : currentRole === ECabinetRole.BUYER) {
      navigate(`../../offers/${offer_id}`, { replace: true, relative: "path" });
    }
  }, [currentRole, isAdmin, isAdminSeller, navigate, offer_id, user]);

  useEffect(() => {
    offer_id && getOffer();
  }, [getOffer, offer_id]);

  useEffect(() => {
    offerForSeller && getApplication(offerForSeller?.application_id);
  }, [getApplication, offerForSeller]);

  useEffect(() => {
    offerForSeller && getFiles(offerForSeller?.offer_id);
  }, [getFiles, offerForSeller]);

  useEffect(() => {
    !accounts && getAccounts();
  }, [accounts, getAccounts]);

  useEffect(() => {
    !entities && getEntities();
  }, [entities, getEntities]);

  useEffect(() => {
    return () => {
      dispatch(offerForSellerUpdateStatusActions.clearState());
      dispatch(offerForSellerUpdateActions.clearState());
      dispatch(offerForSellerByIdActions.clearState());
      dispatch(ApplicationForSellerByIdActions.clearState());
      dispatch(filesByObjectActions.clearState());
    };
  }, [dispatch]);

  return (
    <Wrapper>
      <Typography.Title
        level={3}
        style={{
          marginTop: 0,
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          flexWrap: "wrap",
          gap: "12px"
        }}
      >
        Предложение #{offerForSeller?.offer_id}
        {statusData ? (
          <EnlargedTag color={statusData?.color}>
            {statusData?.shortName}
          </EnlargedTag>
        ) : (
          offerForSeller?.status_id
        )}
      </Typography.Title>

      {offerForSeller ? (
        <>
          {(canUpdate || canChangeStatus) && (
            <CTABlock>
              {canUpdate && (
                <Button
                  icon={isEditing ? <CloseOutlined /> : <EditOutlined />}
                  onClick={isEditing ? onCancelEdit : onEdit}
                >
                  {isEditing ? "Отмена" : "Редактировать"}
                </Button>
              )}

              {canChangeStatus && !isEditing && (
                <Dropdown
                  menu={{ items: statusOptions, onClick: onUpdateStatus }}
                >
                  <Button
                    loading={statusIsLoading}
                    style={{ marginLeft: "auto" }}
                  >
                    <Space>
                      Изменить статус
                      <DownOutlined />
                    </Space>
                  </Button>
                </Dropdown>
              )}
            </CTABlock>
          )}

          <ErrorAlert error={statusError} />

          {isEditing ? (
            <OfferEditForm
              offer={offerForSeller}
              application={application}
              entitiesForOffer={entitiesForOffer}
              accounts={accounts}
              onSubmit={onUpdate}
              isLoading={updateIsLoading}
              error={updateError}
            />
          ) : (
            <>
              {application && (
                <ApplicationsTable
                  applications={[application]}
                  forSeller
                  pagination={false}
                  fromOffer
                />
              )}

              <OfferData offer={offerForSeller} forSeller />
            </>
          )}

          <Files
            id={offerForSeller?.offer_id}
            type={EFileType.OFFER}
            files={files}
            onFinish={() => getFiles(offerForSeller?.offer_id)}
            canEdit={canEditFiles}
          />
        </>
      ) : (
        <Alert message="Данные по предложению отсутствуют" showIcon />
      )}
    </Wrapper>
  );
};
