import { TLicenseType } from "types";
import { apiRequest, RequestPromise } from "utils/api";

export * from "./id";
export * from "./add";
export * from "./update";
export * from "./delete";

export const getLicenseTypes = (): RequestPromise<TGetLicenseTypesResponse> => {
  return apiRequest.get(`/license/type/`);
};

export type TGetLicenseTypesResponse = TLicenseType[];
