import React, { useCallback, useEffect } from "react";

import { useAppDispatch, useAppSelector } from "store/store";
import { BasicTitle, OffersTable } from "components/common/redesign";
import { PAGE_SIZES } from "constants/pagination";
import { SearchForm } from "./components";
import { Wrapper, Header } from "../../Offers.styles";
import {
  offersForBuyerGetActions,
  offersForBuyerGetSelectors
} from "store/offersForBuyer/get";
import { getOffersForBuyer } from "store/offersForBuyer/get/thunk";
import { userSelectors } from "store/user";

export const OffersForBuyer = () => {
  const dispatch = useAppDispatch();

  const { user } = useAppSelector(userSelectors.getState);
  const { offersForBuyer, totalElements, page, size, sort, isLoading } =
    useAppSelector(offersForBuyerGetSelectors.getState);

  const getOffersArr = useCallback(() => {
    dispatch(getOffersForBuyer());
  }, [dispatch]);

  const onSearch = useCallback(
    (search?: string) => {
      dispatch(offersForBuyerGetActions.setPage(1));
      dispatch(offersForBuyerGetActions.setSearch(search));
      getOffersArr();
    },
    [dispatch, getOffersArr]
  );

  const onPaginationChange = useCallback(
    (page: number, pageSize: number) => {
      if (pageSize === size) {
        dispatch(offersForBuyerGetActions.setPage(page));
      } else {
        dispatch(offersForBuyerGetActions.setPage(1));
        dispatch(offersForBuyerGetActions.setSize(pageSize));
      }
      getOffersArr();
    },
    [dispatch, getOffersArr, size]
  );

  const onSortChange = useCallback(
    (newSort: string, direction: boolean) => {
      if (newSort !== sort) {
        dispatch(offersForBuyerGetActions.setSort(newSort));
        dispatch(offersForBuyerGetActions.setPage(1));
      }
      dispatch(offersForBuyerGetActions.setAsc(direction));
      getOffersArr();
    },
    [dispatch, getOffersArr, sort]
  );

  useEffect(() => {
    getOffersArr();
  }, [getOffersArr]);

  useEffect(() => {
    return () => {
      dispatch(offersForBuyerGetActions.clearState());
    };
  }, [dispatch]);

  return (
    <Wrapper>
      <BasicTitle>Предложения по заявкам</BasicTitle>

      <Header>
        <SearchForm onSearch={onSearch} isLoading={isLoading} />
      </Header>

      <OffersTable
        user={user}
        offers={offersForBuyer}
        pagination={{
          current: page,
          onChange: onPaginationChange,
          pageSize: size,
          total: totalElements,
          showSizeChanger: true,
          pageSizeOptions: PAGE_SIZES
        }}
        onSort={onSortChange}
      />
    </Wrapper>
  );
};
