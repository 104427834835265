import React, { useCallback, useEffect } from "react";

import { useAppDispatch, useAppSelector } from "store/store";
import { userSelectors } from "store/user";
import { BasicTitle, OffersTable } from "components/common/redesign";
import { PAGE_SIZES } from "constants/pagination";
import { getColleaguesByCabinet } from "store/colleagues/byCabinet/thunk";
import { colleaguesByCabinetSelectors } from "store/colleagues/byCabinet";
import { SearchForm } from "./components";
import { Wrapper, Header } from "../../Offers.styles";
import {
  offersForSellerGetActions,
  offersForSellerGetSelectors
} from "store/offersForSeller/get";
import { getOffersForSeller } from "store/offersForSeller/get/thunk";

export const OffersForSeller = () => {
  const dispatch = useAppDispatch();

  const { user } = useAppSelector(userSelectors.getState);
  const { offersForSeller, totalElements, page, size, sort, isLoading } =
    useAppSelector(offersForSellerGetSelectors.getState);
  const { users } = useAppSelector(colleaguesByCabinetSelectors.getState);

  const getOffersArr = useCallback(() => {
    dispatch(getOffersForSeller());
  }, [dispatch]);

  const getColleagues = useCallback(
    (cabinet_id: number) => {
      dispatch(getColleaguesByCabinet(cabinet_id));
    },
    [dispatch]
  );

  const onSearch = useCallback(
    (search?: string) => {
      dispatch(offersForSellerGetActions.setPage(1));
      dispatch(offersForSellerGetActions.setSearch(search));
      getOffersArr();
    },
    [dispatch, getOffersArr]
  );

  const onPaginationChange = useCallback(
    (page: number, pageSize: number) => {
      if (pageSize === size) {
        dispatch(offersForSellerGetActions.setPage(page));
      } else {
        dispatch(offersForSellerGetActions.setPage(1));
        dispatch(offersForSellerGetActions.setSize(pageSize));
      }
      getOffersArr();
    },
    [dispatch, getOffersArr, size]
  );

  const onSortChange = useCallback(
    (newSort: string, direction: boolean) => {
      if (newSort !== sort) {
        dispatch(offersForSellerGetActions.setSort(newSort));
        dispatch(offersForSellerGetActions.setPage(1));
      }
      dispatch(offersForSellerGetActions.setAsc(direction));
      getOffersArr();
    },
    [dispatch, getOffersArr, sort]
  );

  useEffect(() => {
    getOffersArr();
  }, [getOffersArr]);

  useEffect(() => {
    !users && user && getColleagues(user?.cabinet?.cabinet_id);
  }, [users, getColleagues, user]);

  useEffect(() => {
    return () => {
      dispatch(offersForSellerGetActions.clearState());
    };
  }, [dispatch]);

  return (
    <Wrapper>
      <BasicTitle>Предложения</BasicTitle>

      <Header>
        <SearchForm
          onSearch={onSearch}
          isLoading={isLoading}
          entities={user?.entities}
          users={users}
        />
      </Header>

      <OffersTable
        user={user}
        offers={offersForSeller}
        pagination={{
          current: page,
          onChange: onPaginationChange,
          pageSize: size,
          total: totalElements,
          showSizeChanger: true,
          pageSizeOptions: PAGE_SIZES
        }}
        onSort={onSortChange}
        forSeller
      />
    </Wrapper>
  );
};
