import { EFileType, TFileMeta } from "types";
import { RequestPromise, apiRequest } from "utils/api";

export const getFilesByObject = (
  data: TFilesByObjectRequest
): RequestPromise<TFilesByObjectResponse> => {
  return apiRequest.get(`/files/object`, {
    params: data
  });
};

export type TFilesByObjectRequest = { id: number; type: EFileType };

export type TFilesByObjectResponse = TFileMeta[];
