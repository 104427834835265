import React, { FC, useCallback, useMemo } from "react";
import {
  TApplication,
  TApplicationForSeller,
  TApplicationsTableSettings,
  TPerson,
  TPersonEntity,
  TProject
} from "types";
import {
  Button,
  Pagination,
  Table,
  TableColumnProps,
  TableColumnsType
} from "antd";
import { TablePaginationConfig, TableProps } from "antd/es/table";
import { toDisplayDate } from "utils/dates";
import { Link } from "react-router-dom";
import { TableScrollWrapper, TableWrapper } from "./ApplicationsTable.styles";
import { Empty } from "../Empty/Empty";
import { ApplicationStatusTag } from "../ApplicationStatusTag/ApplicationStatusTag";
import { useAppSelector } from "store/store";
import { applicationsTableSettingsSelectors } from "store/settings/applicationsTable";
import { projectRegionIdMapper } from "utils/mappers";

type TTableOnChange = Required<TableProps<TApplicationForTable>>["onChange"];
type TTableOnChangeArgs = Parameters<TTableOnChange>;

type TApplicationForTable = {
  application_id: number;
  application_name: string;
  application_status_id: number;
  buyer_entity_name?: string;
  project_name?: string;
  creator_name: string;
  responsible_name: string;
  positions_length: number;
  application_delivery_due_timestamp?: string;
  application_comment?: string;
  can_message: boolean;
  application_creation_timestamp?: string;
  application_modified_timestamp?: string;
  application_region: string;
  delivery?: string;
  postpayment?: string;
};

type TApplicationsTableProps = {
  user?: TPerson;
  applications?: TApplication[] | TApplicationForSeller[];
  pagination?: TablePaginationConfig | false;
  onSort?: (newSort: string, direction: boolean) => void;
  projects?: TProject[];
  entities?: TPersonEntity[];
  forSeller?: boolean;
  fromOffer?: boolean;
};

export const ApplicationsTable: FC<TApplicationsTableProps> = ({
  user,
  applications,
  projects,
  pagination,
  onSort,
  entities,
  forSeller,
  fromOffer
}) => {
  const { settings } = useAppSelector(
    applicationsTableSettingsSelectors.getState
  );

  const applicationsForTable: TApplicationForTable[] = useMemo(
    () =>
      (applications || []).map((application) => {
        const project = projects?.find(
          (project) =>
            project?.project_id === (application as TApplication)?.project_id
        );
        const entity = entities?.find(
          (entity) => entity?.entity?.entity_id === project?.entity_id
        )?.entity;
        return {
          application_id: application?.application_id,
          application_name: application?.application_name,
          application_status_id: application?.application_status_id,
          buyer_entity_name: forSeller
            ? (application as TApplicationForSeller)?.buyer_entity?.full_name
            : entity?.full_name,
          project_name: project?.project_name,
          creator_name: (application as TApplication)?.creator?.full_name,
          responsible_name: application?.responsible?.full_name,
          positions_length: application?.positions?.length || 0,
          application_delivery_due_timestamp: toDisplayDate(
            application?.application_delivery_due_timestamp
          ),
          application_comment: application?.application_comment,
          application_creation_timestamp: toDisplayDate(
            application?.application_creation_timestamp
          ),
          application_modified_timestamp: toDisplayDate(
            application?.application_modified_timestamp
          ),
          application_region:
            projectRegionIdMapper[application?.application_region_id]?.fullName,
          delivery: application?.is_need_delivery
            ? application?.location?.address
            : "Нет",
          postpayment: application?.is_postpayment
            ? application?.postpayment_days?.toString()
            : "Нет",
          can_message: true
        };
      }),
    [applications, entities, forSeller, projects]
  );

  const columns: TableColumnsType<TApplicationForTable> = useMemo(
    () =>
      [
        {
          title: "Наименование",
          key: "application_name",
          render: (value: TApplicationForTable) => (
            <Link
              to={
                fromOffer
                  ? `${"../../applications"}/${value?.application_id}`
                  : `${value?.application_id}`
              }
            >
              {[value?.application_id, value?.application_name].join(" - ")}
            </Link>
          ),
          minWidth: 213,
          sorter: true,
          sortingKey: "id"
        },
        {
          title: "Статус",
          key: "application_status_id",
          dataIndex: "application_status_id",
          render: (value: number) => {
            return <ApplicationStatusTag status_id={value} />;
          },
          minWidth: 104,
          sorter: true,
          sortingKey: "status"
        },
        {
          title: "Юр. лицо",
          key: "buyer_entity_name",
          dataIndex: "buyer_entity_name",
          minWidth: 168
          // sorter: true,
          // sortingKey: "_____" //TODO
        },
        ...(forSeller
          ? []
          : [
              {
                title: "Проект",
                key: "project_name",
                dataIndex: "project_name",
                minWidth: 155,
                sorter: true,
                sortingKey: "project"
              }
            ]),
        ...(forSeller
          ? []
          : [
              {
                title: "Создатель",
                key: "creator_name",
                dataIndex: "creator_name",
                minWidth: 148,
                sorter: true,
                sortingKey: "creator"
              }
            ]),
        {
          title: "Ответственный",
          key: "responsible_name",
          dataIndex: "responsible_name",
          minWidth: 148,
          sorter: true,
          sortingKey: "responsible"
        },
        {
          title: "Позиций",
          key: "positions_length",
          dataIndex: "positions_length",
          minWidth: 76
          // sorter: true,
          // sortingKey: "_____" //TODO
        },
        {
          title: "Дата поставки",
          key: "application_delivery_due_timestamp",
          dataIndex: "application_delivery_due_timestamp",
          minWidth: 108,
          sorter: true,
          sortingKey: "deliveryDueTs"
        },
        {
          title: "Дата создания",
          key: "application_creation_timestamp",
          dataIndex: "application_creation_timestamp",
          minWidth: 108,
          sorter: true,
          sortingKey: "creationTs",
          defaultSortOrder: "descend" as TableColumnProps["sortOrder"]
        },
        {
          title: "Дата изменения",
          key: "application_modified_timestamp",
          dataIndex: "application_modified_timestamp",
          minWidth: 108,
          sorter: true,
          sortingKey: "modifiedTs"
        },
        {
          title: "Регион",
          key: "application_region",
          dataIndex: "application_region",
          minWidth: 108
          // sorter: true,
          // sortingKey: "_____" //TODO
        },
        {
          title: "Адрес доставки",
          key: "delivery",
          dataIndex: "delivery",
          minWidth: 200
          // sorter: true,
          // sortingKey: "_____" //TODO
        },
        {
          title: "Кол-во дней отложенного платежа",
          key: "postpayment",
          dataIndex: "postpayment",
          minWidth: 200,
          sorter: true,
          sortingKey: "postpaymentDays"
        },
        {
          title: "Комментарий",
          key: "application_comment",
          dataIndex: "application_comment",
          minWidth: 285,
          sorter: true,
          sortingKey: "comment"
        },
        {
          title: "Сообщения",
          key: "can_message",
          render: (value: TApplicationForTable) => {
            return (
              value?.can_message && (
                <Link
                  to={
                    forSeller
                      ? `../messages/${value?.application_id}/${user?.cabinet?.cabinet_id}`
                      : `${value?.application_id}/messages`
                  }
                >
                  <Button type="link">Сообщения по заявке</Button>
                </Link>
              )
            );
          }
        }
      ].filter(
        (column) => settings[column?.key as keyof TApplicationsTableSettings]
      ),
    [forSeller, fromOffer, settings, user]
  );

  const onSortHandler: TTableOnChange = useCallback(
    (_, __, sorter: TTableOnChangeArgs[2]) => {
      const sort = (sorter as any)?.column?.sortingKey as string;
      const asc = (sorter as any)?.order === "ascend";
      onSort?.(sort, asc);
    },
    [onSort]
  );

  return (
    <TableWrapper>
      {applicationsForTable?.length ? (
        <>
          <TableScrollWrapper>
            <Table
              dataSource={applicationsForTable}
              columns={columns}
              pagination={false}
              rowKey={(row) => row?.application_id}
              onChange={onSortHandler}
              sortDirections={onSort ? ["ascend", "descend", "ascend"] : []}
              showSorterTooltip={!!onSort}
            />
          </TableScrollWrapper>
          {pagination && <Pagination {...pagination} align="end" />}
        </>
      ) : (
        <Empty description="Пока нет ни одной заявки" style={{ flex: 1 }} />
      )}
    </TableWrapper>
  );
};
