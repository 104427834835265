import React, { FC, useCallback, useMemo } from "react";
import {
  TOfferForBuyer,
  TOfferForSeller,
  TOffersTableSettings,
  TPerson
} from "types";
import {
  Button,
  Pagination,
  Table,
  TableColumnProps,
  TableColumnsType,
  Typography
} from "antd";
import { TablePaginationConfig, TableProps } from "antd/es/table";
import { toDisplayDate } from "utils/dates";
import { Link } from "react-router-dom";
import {
  PriceBlock,
  TableScrollWrapper,
  TableWrapper
} from "./OffersTable.styles";
import { Empty } from "../Empty/Empty";
import { TypographyTitleNoMargin } from "../TypographyTitleNoMargin/TypographyTitleNoMargin";
import { countOfferPositionsPrice } from "utils/countOfferPositionsPrice";
import { padFractionalZeros, splitThousands } from "utils/numbers";
import { OfferStatusTag } from "../OfferStatusTag/OfferStatusTag";
import { useAppSelector } from "store/store";
import { offersTableSettingsSelectors } from "store/settings/offersTable";
import { OffersTableSettings } from "../OffersTableSettings/OffersTableSettings";
import { projectRegionIdMapper } from "utils/mappers";

type TTableOnChange = Required<TableProps<TOfferForTable>>["onChange"];
type TTableOnChangeArgs = Parameters<TTableOnChange>;

type TOfferForTable = {
  offer_id: number;
  create_ts?: string;
  update_ts?: string;
  delivary_plan_ts?: string;
  application_id: number;
  application_name: string;
  status_id: number;
  creator_name: string;
  positions_length: number;
  offer_comment?: string;
  seller_entity_name: string;
  price: {
    price: number;
    priceVAT: number;
  };
  can_message: boolean;
  seller_cabinet_id: number;
  offer_region_id: string;
  delivery?: string;
  postpayment?: string;
};

type TOffersTableProps = {
  user?: TPerson;
  offers?: TOfferForBuyer[] | TOfferForSeller[];
  pagination?: TablePaginationConfig | false;
  onSort?: (newSort: string, direction: boolean) => void;
  forSeller?: boolean;
  fromApplication?: boolean;
};

export const OffersTable: FC<TOffersTableProps> = ({
  offers,
  pagination,
  onSort,
  forSeller,
  fromApplication
}) => {
  const { settings } = useAppSelector(offersTableSettingsSelectors.getState);

  const offersForTable: TOfferForTable[] = useMemo(
    () =>
      (offers || []).map((offer) => {
        return {
          offer_id: offer?.offer_id,
          application_id: offer?.application_id,
          application_name: offer?.application_name,
          status_id: offer?.status_id,
          creator_name: offer?.creator?.full_name,
          positions_length: offer?.positions?.length || 0,
          offer_comment: offer?.offer_comment,
          seller_entity_name: offer?.seller_entity?.full_name,
          price: countOfferPositionsPrice(offer?.positions),
          create_ts: toDisplayDate(offer?.create_ts),
          update_ts: toDisplayDate(offer?.update_ts),
          delivary_plan_ts: toDisplayDate(offer?.delivary_plan_ts),
          offer_region_id:
            projectRegionIdMapper[offer?.offer_region_id]?.fullName,
          delivery: offer?.is_need_delivery ? offer?.location?.address : "Нет",
          postpayment: offer?.is_postpayment
            ? offer?.postpayment_days?.toString()
            : "Нет",
          can_message: true,
          seller_cabinet_id: offer?.seller_entity?.cabinet_id
        };
      }),
    [offers]
  );

  const columns: TableColumnsType<TOfferForTable> = useMemo(
    () =>
      [
        {
          title: "Наименование",
          key: "offer_name",
          render: (value: TOfferForTable) => (
            <Link
              to={
                fromApplication
                  ? `${"../../offers"}/${value?.offer_id}`
                  : `${value?.offer_id}`
              }
            >
              {value?.offer_id} {value?.create_ts && `от ${value?.create_ts}`}
            </Link>
          ),
          minWidth: 170,
          sorter: true,
          sortingKey: "id"
        },
        ...(!fromApplication
          ? [
              {
                title: "Заявка",
                key: "application_name",
                render: (value: TOfferForTable) => (
                  <Link to={`../applications/${value?.application_id}`}>
                    {[value?.application_id, value?.application_name].join(
                      " - "
                    )}
                  </Link>
                ),
                minWidth: 213,
                sorter: true,
                sortingKey: "application"
              }
            ]
          : []),
        {
          title: "Статус",
          key: "status_id",
          dataIndex: "status_id",
          render: (value: number) => {
            return <OfferStatusTag status_id={value} />;
          },
          minWidth: 224,
          sorter: true,
          sortingKey: "status"
        },
        {
          title: "Юр. лицо",
          key: "seller_entity_name",
          dataIndex: "seller_entity_name",
          minWidth: 218,
          sorter: true,
          sortingKey: "legalEntity"
        },
        {
          title: "Создатель",
          key: "creator_name",
          dataIndex: "creator_name",
          minWidth: 160,
          sorter: true,
          sortingKey: "creator"
        },
        {
          title: "Позиций",
          key: "positions_length",
          dataIndex: "positions_length",
          minWidth: 112
          // sorter: true,
          // sortingKey: "_____" //TODO
        },
        {
          title: "Стоимость",
          key: "price",
          dataIndex: "price",
          minWidth: 240,
          render: (value: TOfferForTable["price"]) => (
            <PriceBlock>
              <Typography.Text>
                {splitThousands(padFractionalZeros(value?.priceVAT))} руб.
              </Typography.Text>
              <Typography.Text type="secondary">
                без НДС {splitThousands(padFractionalZeros(value?.price))} руб.
              </Typography.Text>
            </PriceBlock>
          )
          // sorter: true,
          // sortingKey: "_____" //TODO
        },
        {
          title: "Дата поставки",
          key: "delivary_plan_ts",
          dataIndex: "delivary_plan_ts",
          minWidth: 108,
          sorter: true,
          sortingKey: "deliveryPlanTs"
        },
        {
          title: "Дата создания",
          key: "create_ts",
          dataIndex: "create_ts",
          minWidth: 108,
          sorter: true,
          sortingKey: "createTs",
          defaultSortOrder: "descend" as TableColumnProps["sortOrder"]
        },
        {
          title: "Дата изменения",
          key: "update_ts",
          dataIndex: "update_ts",
          minWidth: 108,
          sorter: true,
          sortingKey: "updateTs"
        },
        {
          title: "Регион",
          key: "offer_region_id",
          dataIndex: "offer_region_id",
          minWidth: 108
          // sorter: true,
          // sortingKey: "_____" //TODO
        },
        {
          title: "Адрес доставки",
          key: "delivery",
          dataIndex: "delivery",
          minWidth: 200
          // sorter: true,
          // sortingKey: "_____" //TODO
        },
        {
          title: "Кол-во дней отложенного платежа",
          key: "postpayment",
          dataIndex: "postpayment",
          minWidth: 200
          // sorter: true,
          // sortingKey: "_____" //TODO
        },
        {
          title: "Комментарий",
          key: "offer_comment",
          dataIndex: "offer_comment",
          minWidth: 278,
          sorter: true,
          sortingKey: "comment"
        },
        {
          title: "Сообщения",
          key: "can_message",
          render: (value: TOfferForTable) => {
            return (
              value?.can_message && (
                <Link
                  to={
                    fromApplication
                      ? forSeller
                        ? `../../messages/${value?.application_id}/${value?.seller_cabinet_id}`
                        : `messages/${value?.application_id}/${value?.seller_cabinet_id}`
                      : forSeller
                        ? `../messages/${value?.application_id}/${value?.seller_cabinet_id}`
                        : `../applications/${value?.application_id}/messages/${value?.application_id}/${value?.seller_cabinet_id}`
                  }
                >
                  <Button type="link">Сообщения</Button>
                </Link>
              )
            );
          }
        }
      ].filter((column) => settings[column?.key as keyof TOffersTableSettings]),
    [fromApplication, settings, forSeller]
  );

  const onSortHandler: TTableOnChange = useCallback(
    (_, __, sorter: TTableOnChangeArgs[2]) => {
      const sort = (sorter as any)?.column?.sortingKey as string;
      const asc = (sorter as any)?.order === "ascend";
      onSort?.(sort, asc);
    },
    [onSort]
  );

  return (
    <TableWrapper>
      {fromApplication && (
        <>
          <TypographyTitleNoMargin level={5}>
            Предложения <OffersTableSettings size="middle" />
          </TypographyTitleNoMargin>
        </>
      )}

      {offersForTable?.length ? (
        <>
          <TableScrollWrapper>
            <Table
              dataSource={offersForTable}
              columns={columns}
              pagination={false}
              rowKey={(row) => row?.offer_id}
              onChange={onSortHandler}
              sortDirections={onSort ? ["ascend", "descend", "ascend"] : []}
              showSorterTooltip={!!onSort}
            />
          </TableScrollWrapper>
          {pagination && <Pagination {...pagination} align="end" />}
        </>
      ) : (
        <Empty
          description="Пока нет ни одного предложения"
          style={{ flex: 1 }}
        />
      )}
    </TableWrapper>
  );
};
