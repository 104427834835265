import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { TApplicationForSellerBestPricesByIdState } from "./types";

import { RequestError, TPriceByPosition } from "types";

const initialState: TApplicationForSellerBestPricesByIdState = {
  isLoading: false,
  error: null,
  bestPrices: undefined
};

export const ApplicationForSellerBestPricesByIdSlice = createSlice({
  name: "applicationsForSeller/bestPricesById",
  initialState,
  reducers: {
    clearState: () => initialState,
    setBestPrices: (state, { payload }: PayloadAction<TPriceByPosition[]>) => {
      state.bestPrices = payload;
    },
    setLoading: (state, { payload }: PayloadAction<boolean>) => {
      state.isLoading = payload;
    },
    setError: (state, { payload }: PayloadAction<RequestError | null>) => {
      state.error = payload;
    }
  }
});

export const applicationForSellerBestPricesByIdActions =
  ApplicationForSellerBestPricesByIdSlice.actions;
export const applicationForSellerBestPricesByIdReducer =
  ApplicationForSellerBestPricesByIdSlice.reducer;

export * as applicationForSellerBestPricesByIdSelectors from "./selectors";
