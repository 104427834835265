import { TChat } from "types";
import { RequestPromise, apiRequest } from "utils/api";

export const getChat = (
  data: TGetChatRequest
): RequestPromise<TGetChatResponse> => {
  return apiRequest.post(`/chat/get`, data);
};

export type TGetChatRequest = {
  applicationId: number;
  sellerCabinetId: number;
};

export type TGetChatResponse = TChat;
