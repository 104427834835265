import React, { useCallback, useMemo } from "react";

import { Wrapper } from "./Help.styles";
import { Button, List } from "antd";
import { useAppSelector } from "store/store";
import { userSelectors } from "store/user";
import { BasicTitle, UnauthorizedFormTitle } from "components/common/redesign";
import {
  CopyOutlined,
  FileTextFilled,
  MailFilled,
  MessageFilled,
  PhoneFilled
} from "@ant-design/icons";
import { Link } from "react-router-dom";

// - ссылка на вики https://wiki.contractor-b2b.ru/
// - Почта support@contractor-b2b.ru
// - Телеграмм t.me/Contractor_Help_Bot
// - Номер телефона +78001001051

export const Help = () => {
  const { user } = useAppSelector(userSelectors.getState);

  const data = useMemo(
    () => [
      {
        title: "Документация",
        icon: <FileTextFilled />,
        description: (
          <Link
            to="https://wiki.contractor-b2b.ru/docs/cntr_intro"
            target="_blank"
          >
            wiki.contractor-b2b.ru
          </Link>
        )
      },
      {
        title: "Telegram",
        icon: <MessageFilled />,
        description: (
          <Link to="https://t.me/Contractor_Help_Bot" target="_blank">
            Contractor Help Bot
          </Link>
        )
      },
      {
        title: "Email",
        icon: <MailFilled />,
        description: (
          <Link to="mailto:support@contractor-b2b.ru">
            support@contractor-b2b.ru
          </Link>
        ),
        valueForCopy: "support@contractor-b2b.ru"
      },
      {
        title: "Телефон",
        icon: <PhoneFilled />,
        description: <Link to="tel:+78001001051">+78001001051</Link>,
        valueForCopy: "+78001001051"
      }
    ],
    []
  );
  const onCopy = useCallback((data: string) => {
    navigator.clipboard.writeText(data);
  }, []);

  return (
    <Wrapper>
      {user ? (
        <BasicTitle>Поддержка</BasicTitle>
      ) : (
        <UnauthorizedFormTitle>Поддержка</UnauthorizedFormTitle>
      )}

      <List
        size="large"
        itemLayout="horizontal"
        dataSource={data}
        renderItem={({ title, icon, description, valueForCopy }) => (
          <List.Item
            key={title}
            actions={
              valueForCopy
                ? [
                    <Button
                      icon={<CopyOutlined />}
                      onClick={() => onCopy(valueForCopy)}
                    >
                      Скопировать
                    </Button>
                  ]
                : undefined
            }
          >
            <List.Item.Meta
              avatar={icon}
              title={title}
              description={description}
            />
          </List.Item>
        )}
      />

      {!user && (
        <Link to="/login">
          <Button block size="large">
            Назад
          </Button>
        </Link>
      )}
    </Wrapper>
  );
};
