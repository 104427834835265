import React, { useCallback, useEffect } from "react";
import { useAppDispatch, useAppSelector } from "store/store";
import { ApplicationsTable, BasicTitle } from "components/common/redesign";
import { PAGE_SIZES } from "constants/pagination";
import { SearchForm } from "./components";
import { Wrapper, Header } from "../../Applications.styles";
import {
  applicationsForSellerGetActions,
  applicationsForSellerGetSelectors
} from "store/applicationsForSeller/get";
import { getApplicationsForSeller } from "store/applicationsForSeller/get/thunk";
import { userSelectors } from "store/user";

export const ApplicationsForSeller = () => {
  const dispatch = useAppDispatch();

  const { user } = useAppSelector(userSelectors.getState);
  const {
    applications,
    totalElements,
    page,
    size,
    sort,
    isLoading: applicationsLoading
  } = useAppSelector(applicationsForSellerGetSelectors.getState);

  const getApplicationsArr = useCallback(() => {
    dispatch(getApplicationsForSeller());
  }, [dispatch]);

  const onSearch = useCallback(
    (search?: string) => {
      dispatch(applicationsForSellerGetActions.setPage(1));
      dispatch(applicationsForSellerGetActions.setSearch(search));
      getApplicationsArr();
    },
    [dispatch, getApplicationsArr]
  );

  const onPaginationChange = useCallback(
    (page: number, pageSize: number) => {
      if (pageSize === size) {
        dispatch(applicationsForSellerGetActions.setPage(page));
      } else {
        dispatch(applicationsForSellerGetActions.setPage(1));
        dispatch(applicationsForSellerGetActions.setSize(pageSize));
      }
      getApplicationsArr();
    },
    [dispatch, getApplicationsArr, size]
  );

  const onSortChange = useCallback(
    (newSort: string, direction: boolean) => {
      if (newSort !== sort) {
        dispatch(applicationsForSellerGetActions.setSort(newSort));
        dispatch(applicationsForSellerGetActions.setPage(1));
      }
      dispatch(applicationsForSellerGetActions.setAsc(direction));
      getApplicationsArr();
    },
    [dispatch, getApplicationsArr, sort]
  );

  useEffect(() => {
    getApplicationsArr();
  }, [getApplicationsArr]);

  useEffect(() => {
    return () => {
      dispatch(applicationsForSellerGetActions.clearState());
    };
  }, [dispatch]);

  return (
    <Wrapper>
      <BasicTitle>Доступные заявки</BasicTitle>

      <Header>
        <SearchForm onSearch={onSearch} isLoading={applicationsLoading} />
      </Header>

      <ApplicationsTable
        user={user}
        applications={applications}
        forSeller
        pagination={{
          current: page,
          onChange: onPaginationChange,
          pageSize: size,
          total: totalElements,
          showSizeChanger: true,
          pageSizeOptions: PAGE_SIZES
        }}
        onSort={onSortChange}
      />
    </Wrapper>
  );
};
