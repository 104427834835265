import { TApplication } from "types";
import { RequestPromise, apiRequest } from "utils/api";

export const updateApplication = (
  data: TUpdateApplicationRequest
): RequestPromise<TUpdateApplicationResponse> => {
  return apiRequest.post(`/applications/update`, data);
};

export type TUpdateApplicationRequest = TApplication;

export type TUpdateApplicationResponse = TApplication;
