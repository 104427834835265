import React, { useCallback, useEffect, useMemo } from "react";
import {
  ApplicationsTable,
  EnlargedTag,
  ErrorAlert,
  Files,
  OfferData
} from "components/common/old";
import { useAppDispatch, useAppSelector } from "store/store";
import {
  Alert,
  Button,
  Dropdown,
  MenuProps,
  Space,
  Tag,
  Typography
} from "antd";
import { useNavigate, useParams } from "react-router-dom";
import { userSelectors } from "store/user";
import { CTABlock, Wrapper } from "./OfferForBuyer.styles";
import { DownOutlined } from "@ant-design/icons";
import { offerStatusIdMapper } from "utils/mappers";
import { offerUpdateForBuyerStatusOptions } from "constants/options";

import {
  offerForBuyerByIdActions,
  offerForBuyerByIdSelectors
} from "store/offersForBuyer/byId";
import {
  offerForBuyerUpdateStatusActions,
  offerForBuyerUpdateStatusSelectors
} from "store/offersForBuyer/update_status";
import {
  ApplicationByIdActions,
  applicationByIdSelectors
} from "store/applications/byId";
import { getOfferForBuyerById } from "store/offersForBuyer/byId/thunk";
import { getApplicationById } from "store/applications/byId/thunk";
import { getProjectsByCabinet } from "store/projects/byCabinet/thunk";
import { projectsByCabinetSelectors } from "store/projects/byCabinet";
import { updateOfferForBuyerStatus } from "store/offersForBuyer/update_status/thunk";
import {
  filesByObjectActions,
  filesByObjectSelectors
} from "store/files/byObject";
import { getFilesByObject } from "store/files/byObject/thunk";
import { entitiesByCabinetIdSelectors } from "store/entities/byCabinetId";
import { adminSelectors } from "store/admin";
import { getEntitiesByCabinetId } from "store/entities/byCabinetId/thunk";
import { ECabinetRole, EFileType } from "types";

export const OfferForBuyer = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const { user, currentRole, isAdmin } = useAppSelector(userSelectors.getState);
  const { offerForBuyer } = useAppSelector(offerForBuyerByIdSelectors.getState);

  const { isLoading: statusIsLoading, error: statusError } = useAppSelector(
    offerForBuyerUpdateStatusSelectors.getState
  );
  const { application } = useAppSelector(applicationByIdSelectors.getState);
  const { projects } = useAppSelector(projectsByCabinetSelectors.getState);
  const { files } = useAppSelector(filesByObjectSelectors.getState);
  const { entities } = useAppSelector(entitiesByCabinetIdSelectors.getState);
  const { selectedCabinet, isAdminSeller } = useAppSelector(
    adminSelectors.getState
  );

  const { offer_id } = useParams<{ offer_id: string }>();

  const cabinet_id = useMemo(
    () => (isAdmin ? selectedCabinet : user?.cabinet?.cabinet_id),
    [isAdmin, selectedCabinet, user?.cabinet?.cabinet_id]
  );

  const statusOptions: MenuProps["items"] = useMemo(
    () =>
      offerForBuyer
        ? (
            offerUpdateForBuyerStatusOptions[offerForBuyer?.status_id] || []
          ).map((status) => {
            const statusData = offerStatusIdMapper[status];
            return {
              key: status,
              label: (
                <Tag color={statusData?.color}>{statusData?.shortName}</Tag>
              )
            };
          })
        : [],
    [offerForBuyer]
  );

  const statusData = useMemo(
    () =>
      offerForBuyer?.status_id
        ? offerStatusIdMapper[offerForBuyer?.status_id]
        : undefined,
    [offerForBuyer]
  );

  const project = useMemo(
    () =>
      projects?.find(
        (project) => project?.project_id === application?.project_id
      ),
    [application?.project_id, projects]
  );

  const personEntity = useMemo(
    () =>
      user?.entities?.find(
        (entity) => entity?.entity?.entity_id === project?.entity_id
      ),
    [project, user]
  );
  const entity = useMemo(
    () =>
      isAdmin
        ? entities?.find((entity) => entity?.entity_id === project?.entity_id)
        : personEntity?.entity,
    [entities, isAdmin, personEntity?.entity, project?.entity_id]
  );

  const canChangeStatus = useMemo(() => {
    return (
      !!statusOptions?.length &&
      (application?.creator?.user_id === user?.user_id ||
        application?.responsible?.user_id === user?.user_id ||
        (personEntity?.role_id && personEntity?.role_id >= 3) ||
        (personEntity?.entity?.cabinet_id === user?.cabinet?.cabinet_id &&
          user?.cabinet_role_id === 2) ||
        isAdmin)
    );
  }, [
    application?.creator?.user_id,
    application?.responsible?.user_id,
    isAdmin,
    personEntity?.entity?.cabinet_id,
    personEntity?.role_id,
    statusOptions?.length,
    user?.cabinet?.cabinet_id,
    user?.cabinet_role_id,
    user?.user_id
  ]);

  const getOffer = useCallback(() => {
    dispatch(getOfferForBuyerById(Number(offer_id)));
  }, [dispatch, offer_id]);

  const getApplication = useCallback(
    (application_id: number) => {
      return dispatch(getApplicationById(application_id));
    },
    [dispatch]
  );

  const getProjects = useCallback(() => {
    cabinet_id && dispatch(getProjectsByCabinet(cabinet_id));
  }, [cabinet_id, dispatch]);

  const getEntities = useCallback(() => {
    cabinet_id && dispatch(getEntitiesByCabinetId(cabinet_id));
  }, [cabinet_id, dispatch]);

  const onUpdateStatus = useCallback(
    ({ key }: { key: string }) => {
      offerForBuyer &&
        dispatch(
          updateOfferForBuyerStatus({
            offer_id: offerForBuyer?.offer_id,
            new_status_id: Number(key)
          })
        )
          .unwrap()
          .then(() => {
            getOffer();
          });
    },
    [dispatch, getOffer, offerForBuyer]
  );

  const getFiles = useCallback(
    (offer_id: number) => {
      dispatch(
        getFilesByObject({
          id: offer_id,
          type: EFileType.OFFER
        })
      );
    },
    [dispatch]
  );

  useEffect(() => {
    if (isAdmin ? isAdminSeller : currentRole === ECabinetRole.SELLER) {
      navigate(`../../myoffers/${offer_id}`, {
        replace: true,
        relative: "path"
      });
    }
  }, [currentRole, isAdmin, isAdminSeller, navigate, offer_id, user]);

  useEffect(() => {
    offer_id && getOffer();
  }, [getOffer, offer_id]);

  useEffect(() => {
    offerForBuyer && getApplication(offerForBuyer?.application_id);
  }, [getApplication, offerForBuyer]);

  useEffect(() => {
    offerForBuyer && getFiles(offerForBuyer?.offer_id);
  }, [getFiles, offerForBuyer]);

  useEffect(() => {
    !projects && getProjects();
  }, [getProjects, projects]);

  useEffect(() => {
    !entities && getEntities();
  }, [entities, getEntities]);

  useEffect(() => {
    return () => {
      dispatch(offerForBuyerUpdateStatusActions.clearState());
      dispatch(offerForBuyerByIdActions.clearState());
      dispatch(ApplicationByIdActions.clearState());
      dispatch(filesByObjectActions.clearState());
    };
  }, [dispatch]);

  return (
    <Wrapper>
      <Typography.Title
        level={3}
        style={{
          marginTop: 0,
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          flexWrap: "wrap",
          gap: "12px"
        }}
      >
        Предложение #{offerForBuyer?.offer_id}
        {statusData ? (
          <EnlargedTag color={statusData?.color}>
            {statusData?.shortName}
          </EnlargedTag>
        ) : (
          offerForBuyer?.status_id
        )}
      </Typography.Title>

      {offerForBuyer ? (
        <>
          {canChangeStatus && (
            <CTABlock>
              <Dropdown
                menu={{ items: statusOptions, onClick: onUpdateStatus }}
              >
                <Button
                  loading={statusIsLoading}
                  style={{ marginLeft: "auto" }}
                >
                  <Space>
                    Изменить статус
                    <DownOutlined />
                  </Space>
                </Button>
              </Dropdown>
            </CTABlock>
          )}

          <ErrorAlert error={statusError} />

          {application && (
            <ApplicationsTable
              applications={[application]}
              projects={project ? [project] : undefined}
              pagination={false}
              entities={entity ? [entity] : undefined}
              fromOffer
            />
          )}

          <OfferData
            offer={offerForBuyer}
            application={application}
            entity={entity}
          />

          <Files
            id={offerForBuyer?.offer_id}
            type={EFileType.OFFER}
            files={files}
            canEdit={false}
          />
        </>
      ) : (
        <Alert message="Данные по предложению отсутствуют" showIcon />
      )}
    </Wrapper>
  );
};
