import { TApplicationApprovement } from "types";
import { RequestPromise, apiRequest } from "utils/api";

export const getApprovementsByUserId = (
  id: TByUserIdApprovementsRequest
): RequestPromise<TByUserIdApprovementsResponse> => {
  return apiRequest.get(`/approvements/user/${id}`);
};

export type TByUserIdApprovementsRequest = number;

export type TByUserIdApprovementsResponse = TApplicationApprovement[];
