import React, { useEffect } from "react";
import { MainRouter } from "../MainRouter/MainRouter";
import { useAppDispatch, useAppSelector } from "store/store";
import { userSelectors } from "store/user";
import { getUser } from "store/user/thunk";

const App = () => {
  const dispatch = useAppDispatch();

  const { user } = useAppSelector(userSelectors.getState);

  useEffect(() => {
    if (!user) {
      dispatch(getUser());
    }
  }, [dispatch, user]);

  return <MainRouter />;
};

export default App;
