import React, { useCallback, useEffect, useMemo } from "react";
import { useAppDispatch, useAppSelector } from "store/store";

import { Wrapper } from "./OffersForSeller.styles";
import { Typography } from "antd";
import { OffersTable } from "components/common/old";
import { userSelectors } from "store/user";
import { useNavigate } from "react-router-dom";
import { SearchForm } from "./compoents";
import {
  offersForSellerGetActions,
  offersForSellerGetSelectors
} from "store/offersForSeller/get";
import { getOffersForSeller } from "store/offersForSeller/get/thunk";
import { PAGE_SIZES } from "constants/pagination";
import { entitiesByCabinetIdSelectors } from "store/entities/byCabinetId";
import { adminSelectors } from "store/admin";
import { getEntitiesByCabinetId } from "store/entities/byCabinetId/thunk";
import { ECabinetRole } from "types";

export const OffersForSeller = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const { user, currentRole, isAdmin } = useAppSelector(userSelectors.getState);
  const { offersForSeller, totalElements, page, size, isLoading } =
    useAppSelector(offersForSellerGetSelectors.getState);
  const { entities } = useAppSelector(entitiesByCabinetIdSelectors.getState);
  const { selectedCabinet, isAdminSeller } = useAppSelector(
    adminSelectors.getState
  );

  const cabinet_id = useMemo(
    () => (isAdmin ? selectedCabinet : user?.cabinet?.cabinet_id),
    [isAdmin, selectedCabinet, user?.cabinet?.cabinet_id]
  );

  const getOffersArr = useCallback(() => {
    dispatch(getOffersForSeller());
  }, [dispatch]);

  const getEntities = useCallback(() => {
    cabinet_id && dispatch(getEntitiesByCabinetId(cabinet_id));
  }, [cabinet_id, dispatch]);

  const onSearch = useCallback(
    (search?: string) => {
      dispatch(offersForSellerGetActions.setPage(1));
      dispatch(offersForSellerGetActions.setSearch(search));
      getOffersArr();
    },
    [dispatch, getOffersArr]
  );

  const onPaginationChange = useCallback(
    (page: number, pageSize: number) => {
      if (pageSize === size) {
        dispatch(offersForSellerGetActions.setPage(page));
      } else {
        dispatch(offersForSellerGetActions.setPage(1));
        dispatch(offersForSellerGetActions.setSize(pageSize));
      }
      getOffersArr();
    },
    [dispatch, getOffersArr, size]
  );

  useEffect(() => {
    if (isAdmin ? !isAdminSeller : currentRole === ECabinetRole.BUYER) {
      navigate("../offers", { replace: true, relative: "path" });
    }
  }, [currentRole, isAdmin, isAdminSeller, navigate, user]);

  useEffect(() => {
    getOffersArr();
  }, [getOffersArr]);

  useEffect(() => {
    !entities && getEntities();
  }, [entities, getEntities]);

  useEffect(() => {
    return () => {
      dispatch(offersForSellerGetActions.clearState());
    };
  }, [dispatch]);

  return (
    <Wrapper>
      <Typography.Title level={3} style={{ margin: 0 }}>
        Предложения
      </Typography.Title>

      <SearchForm
        onSearch={onSearch}
        isLoading={isLoading}
        entities={entities}
      />

      <OffersTable
        offers={offersForSeller}
        pagination={{
          current: page,
          onChange: onPaginationChange,
          pageSize: size,
          total: totalElements,
          showSizeChanger: true,
          pageSizeOptions: PAGE_SIZES
        }}
      />
    </Wrapper>
  );
};
