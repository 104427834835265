import { RequestPromise, apiRequest } from "utils/api";

export const updateApplicationStatus = (
  data: TUpdateApplicationStatusRequest
): RequestPromise<TUpdateApplicationStatusResponse> => {
  return apiRequest.post(`/applications/update_status`, data);
};

export type TUpdateApplicationStatusRequest = {
  application_id: number;
  new_status_id: number;
};

export type TUpdateApplicationStatusResponse = {
  application_id: number;
  new_status_id: number;
};
