import React, { FC, useCallback, useMemo, useState } from "react";
import { Button, ButtonProps, Checkbox, Drawer } from "antd";
import { SettingOutlined } from "@ant-design/icons";
import { useAppDispatch, useAppSelector } from "store/store";
import {
  applicationsTableSettingsActions,
  applicationsTableSettingsSelectors
} from "store/settings/applicationsTable";
import { ECabinetRole, TApplicationsTableSettings } from "types";
import { CheckboxOptionType } from "antd/es/checkbox";
import { saveApplicationsTableSettings } from "store/settings/applicationsTable/thunk";
import { userSelectors } from "store/user";

type TApplicationsTableSettingsProps = {
  size?: ButtonProps["size"];
};

export const ApplicationsTableSettings: FC<TApplicationsTableSettingsProps> = ({
  size = "large"
}) => {
  const dispatch = useAppDispatch();

  const [isDrawerOpen, setIsDrawerOpen] = useState(false);

  const { currentRole } = useAppSelector(userSelectors.getState);
  const { settings } = useAppSelector(
    applicationsTableSettingsSelectors.getState
  );

  const options: CheckboxOptionType<keyof TApplicationsTableSettings>[] =
    useMemo(
      () => [
        { label: "Наименование", value: "application_name" },
        { label: "Статус", value: "application_status_id" },
        { label: "Юр. лицо", value: "buyer_entity_name" },
        ...(currentRole === ECabinetRole.SELLER
          ? []
          : [
              {
                label: "Проект",
                value: "project_name" as keyof TApplicationsTableSettings
              },
              {
                label: "Создатель",
                value: "creator_name" as keyof TApplicationsTableSettings
              }
            ]),
        { label: "Ответственный", value: "responsible_name" },
        { label: "Позиций", value: "positions_length" },
        { label: "Дата поставки", value: "application_delivery_due_timestamp" },
        { label: "Дата создания", value: "application_creation_timestamp" },
        { label: "Дата изменения", value: "application_modified_timestamp" },
        { label: "Регион", value: "application_region" },
        { label: "Адрес доставки", value: "delivery" },
        { label: "Кол-во дней отложенного платежа", value: "postpayment" },
        { label: "Комментарий", value: "application_comment" },
        { label: "Сообщения", value: "can_message" }
      ],
      [currentRole]
    );

  const checkboxValue = useMemo(
    () =>
      Object.keys(settings)?.filter(
        (item) => settings[item as keyof TApplicationsTableSettings]
      ) as (keyof TApplicationsTableSettings)[],
    [settings]
  );

  const openDrawer = useCallback(() => {
    setIsDrawerOpen(true);
  }, []);
  const closeDrawer = useCallback(() => {
    setIsDrawerOpen(false);
    dispatch(saveApplicationsTableSettings(settings));
  }, [dispatch, settings]);

  const onChange = useCallback(
    (values: (keyof TApplicationsTableSettings)[]) => {
      if (values?.length) {
        const newSettings: TApplicationsTableSettings = values?.reduce(
          (acc, item) => {
            return { ...acc, [item]: true };
          },
          {}
        );
        dispatch(applicationsTableSettingsActions.setSettings(newSettings));
      }
    },
    [dispatch]
  );

  return (
    <>
      <Button size={size} onClick={openDrawer} icon={<SettingOutlined />} />
      <Drawer
        title="Настройки таблицы заявок"
        open={isDrawerOpen}
        onClose={closeDrawer}
        destroyOnClose
      >
        <Checkbox.Group
          options={options}
          value={checkboxValue}
          onChange={onChange}
          style={{ display: "flex", flexDirection: "column" }}
        />
      </Drawer>
    </>
  );
};
