import { PageableResponse, TOfferForBuyer } from "types";
import { RequestPromise, apiRequest } from "utils/api";

export const getOffersForBuyer = (
  data: TGetOffersForBuyerRequest
): RequestPromise<TGetOffersForBuyerResponse> => {
  return apiRequest.get(`/offers-for-buyers/get`, {
    params: data
  });
};

export type TGetOffersForBuyerRequest = {
  search?: string;
  size: number;
  page: number;
  asc?: boolean;
  sort?: string;
};

export type TGetOffersForBuyerResponse = PageableResponse<TOfferForBuyer>;
