import { TPositionFromDict } from "types";
import { RequestPromise, apiRequest } from "utils/api";

export const getPosition = ({
  search
}: TGetPositionRequest): RequestPromise<TGetPositionResponse> => {
  return apiRequest.get(`/position${search ? `?search=${search}` : ""}`);
};

export type TGetPositionRequest = {
  search: string;
};

export type TGetPositionResponse = TPositionFromDict[];
