import { TPriceByPosition } from "types";
import { RequestPromise, apiRequest } from "utils/api";

export const getApplicationForSellerBestPricesById = (
  id: TGetApplicationForSellerBestPricesByIdRequest
): RequestPromise<TGetApplicationForSellerBestPricesByIdResponse> => {
  return apiRequest.get(`/applications-for-sellers/${id}/best_prices`);
};

export type TGetApplicationForSellerBestPricesByIdRequest = number;

export type TGetApplicationForSellerBestPricesByIdResponse = TPriceByPosition[];
