import { TPriceByPosition, TPricesByPositionMod } from "types";

export const convertBestPrices = (
  pricesByPosition?: TPriceByPosition[]
): TPricesByPositionMod | undefined => {
  if (!pricesByPosition) {
    return;
  }

  const result: TPricesByPositionMod = {};

  pricesByPosition?.forEach((priceByPosition) => {
    priceByPosition?.prices?.forEach((price) => {
      const posId = priceByPosition?.positionId;
      const isAnalogue = price?.isAnalogue ? "analogue" : "original";

      if (!result[posId]) {
        result[posId] = {};
      }

      result[posId][isAnalogue] = {
        fromOwner: price?.fromOwner,
        price: price?.price
      };
    });
  });

  return result;
};
