import { createAsyncThunk } from "@reduxjs/toolkit";
import { offersForBuyerGetActions, offersForBuyerGetSelectors } from ".";
import * as api from "api/offers_for_buyers";
import { RequestError } from "types";
import { serializeError } from "serialize-error";
import { RootState } from "../../store";

export const getOffersForBuyer = createAsyncThunk<
  api.TGetOffersForBuyerResponse,
  void,
  { state: RootState }
>(
  "offersForBuyer/getOfferForBuyers",
  (payload, { dispatch, rejectWithValue, getState }) => {
    dispatch(offersForBuyerGetActions.setLoading(true));
    dispatch(offersForBuyerGetActions.setError(null));

    const { page, size, search, asc, sort } =
      offersForBuyerGetSelectors.getState(getState());

    return api
      .getOffersForBuyer({
        search,
        page: page - 1,
        size,
        asc,
        sort
      })
      .then(({ data }) => {
        dispatch(offersForBuyerGetActions.setOffersForBuyer(data?.content));
        dispatch(
          offersForBuyerGetActions.setTotalElements(data?.totalElements)
        );
        return data;
      })
      .catch((err: RequestError) => {
        dispatch(offersForBuyerGetActions.setError(err));
        return rejectWithValue(serializeError(err));
      })
      .finally(() => {
        dispatch(offersForBuyerGetActions.setLoading(false));
      });
  }
);
