import { TPerson } from "types";
import { RequestPromise, apiRequest } from "utils/api";

export const deleteUser = (
  id: TDeleteUserRequest
): RequestPromise<TDeleteUserResponse> => {
  return apiRequest.post(`/users/delete/${id}`, undefined);
};

export type TDeleteUserRequest = number;

export type TDeleteUserResponse = TPerson;
