import { RequestPromise, apiRequest } from "utils/api";

export const updateOfferForSellerStatus = (
  data: TUpdateOfferForSellerStatusRequest
): RequestPromise<TUpdateOfferForSellerStatusResponse> => {
  return apiRequest.post(`/offers-for-sellers/update_status`, data);
};

export type TUpdateOfferForSellerStatusRequest = {
  offer_id: number;
  new_status_id: number;
};

export type TUpdateOfferForSellerStatusResponse = {
  offer_id: number;
  new_status_id: number;
};
