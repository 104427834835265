import React, { FC, useCallback, useMemo } from "react";
import { TEntity } from "types";
import { Panel } from "../Panel/Panel";
import { Button, List } from "antd";
import {
  CheckOutlined,
  CloseOutlined,
  CopyOutlined,
  HomeOutlined,
  LinkOutlined,
  MailOutlined,
  MehOutlined,
  PhoneOutlined,
  ShopOutlined
} from "@ant-design/icons";

type TCompanyDataProps = {
  entity?: TEntity;
  isSmall?: boolean;
  withCopy?: boolean;
};

export const CompanyData: FC<TCompanyDataProps> = ({
  entity,
  isSmall = false,
  withCopy = false
}) => {
  const companyData = useMemo(() => {
    return [
      {
        label: "Название",
        value: entity?.full_name,
        icon: <ShopOutlined />
      },
      {
        label: "Сокращенное название",
        value: entity?.short_name,
        icon: <ShopOutlined />
      },
      {
        label: "Юридический адрес",
        value: entity?.legal_address,
        icon: <HomeOutlined />
      },
      {
        label: "Физический адрес",
        value: entity?.physical_address,
        icon: <HomeOutlined />
      },
      {
        label: "Email",
        value: entity?.company_email,
        icon: <MailOutlined />
      },
      {
        label: "Телефон",
        value: entity?.company_phone,
        icon: <PhoneOutlined />
      },
      {
        label: "Имя бухгалтера",
        value: entity?.accountant_name,
        icon: <MehOutlined />
      },
      {
        label: "ИНН",
        value: entity?.inn,
        icon: <LinkOutlined />
      },
      {
        label: "ОГРН",
        value: entity?.ogrn,
        icon: <LinkOutlined />
      },
      {
        label: "КПП",
        value: entity?.kpp,
        icon: <LinkOutlined />
      },
      {
        label: "Действующая",
        value: entity?.is_active ? "Действующая" : "Не действующая",
        icon: entity?.is_active ? <CheckOutlined /> : <CloseOutlined />
      }
    ];
  }, [entity]);

  const onCopy = useCallback(() => {
    const data = companyData
      .map((item) => `${item?.label}: ${item?.value || "-"}`)
      .join("\n");
    navigator.clipboard.writeText(data);
  }, [companyData]);

  const copyButton = useMemo(
    () => [
      ...(withCopy
        ? [
            {
              value: (
                <Button onClick={onCopy} icon={<CopyOutlined />}>
                  Копировать данные
                </Button>
              ),
              icon: undefined,
              label: undefined
            }
          ]
        : [])
    ],
    [onCopy, withCopy]
  );
  return (
    <Panel
      style={{
        display: "flex",
        flexDirection: "column"
      }}
      isSmall={isSmall}
    >
      <List
        size={isSmall ? "small" : undefined}
        dataSource={[...companyData, ...copyButton]}
        renderItem={(item) => (
          <List.Item>
            <List.Item.Meta
              avatar={item?.icon}
              title={item?.value}
              description={item?.label}
            />
          </List.Item>
        )}
      />
    </Panel>
  );
};
