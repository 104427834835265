import { TApplication } from "types";
import { RequestPromise, apiRequest } from "utils/api";

export const getApplicationById = (
  id: TGetApplicationByIdRequest
): RequestPromise<TGetApplicationByIdResponse> => {
  return apiRequest.get(`/applications/${id}`);
};

export type TGetApplicationByIdRequest = number;

export type TGetApplicationByIdResponse = TApplication;
