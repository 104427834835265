import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Header, Wrapper } from "../../Profile.styles";
import { useAppDispatch, useAppSelector } from "store/store";
import { userSelectors } from "store/user";
import { TEntity, TLocation } from "types";
import {
  BasicTitle,
  Divider,
  LocationData,
  LocationEditForm,
  TypographyTitleNoMargin
} from "components/common/redesign";
import { Button, Collapse } from "antd";
import { AccordionGroup } from "./LocationsTab.styles";
import { EditOutlined } from "@ant-design/icons";

import { locationAddActions, locationAddSelectors } from "store/locations/add";
import {
  locationUpdateActions,
  locationUpdateSelectors
} from "store/locations/update";
import { locationsByCabinetIdSelectors } from "store/locations/byCabinet";
import { getLocationsByCabinetId } from "store/locations/byCabinet/thunk";
import { addLocation } from "store/locations/add/thunk";
import { updateLocation } from "store/locations/update/thunk";

type TGroupedLocations = {
  locations: TLocation[];
  entity?: TEntity;
}[];

export const LocationsTab = () => {
  const dispatch = useAppDispatch();

  const { user, isAdmin } = useAppSelector(userSelectors.getState);
  const { isLoading: addIsLoading, error: addError } = useAppSelector(
    locationAddSelectors.getState
  );
  const { isLoading: updateIsLoading, error: updateError } = useAppSelector(
    locationUpdateSelectors.getState
  );

  const { locations } = useAppSelector(locationsByCabinetIdSelectors.getState);

  const [isAdding, setIsAdding] = useState(false);
  const [updatingLocation, setUpdatingLocation] = useState<
    TLocation | undefined
  >(undefined);

  const locationsGroups: TGroupedLocations = useMemo(() => {
    const locationByEntityId: Record<number, TLocation[]> = {};

    (locations || []).forEach((location) => {
      if (!locationByEntityId[location.entity_id]) {
        locationByEntityId[location.entity_id] = [];
      }
      locationByEntityId[location.entity_id]?.push(location);
    });

    return Object.keys(locationByEntityId).reduce(
      (acc: TGroupedLocations, entity_id) => {
        const entity = (user?.entities || []).find(
          (entity) => entity?.entity?.entity_id === Number(entity_id)
        )?.entity;

        return [
          ...acc,
          {
            locations: locationByEntityId[Number(entity_id)],
            entity
          }
        ];
      },
      []
    );
  }, [locations, user]);

  const canAddUpdate = useMemo(
    () => user?.cabinet_role_id === 2 || isAdmin,
    [isAdmin, user?.cabinet_role_id]
  );

  const getLocations = useCallback(
    (cabinet_id: number) => {
      dispatch(getLocationsByCabinetId(cabinet_id));
    },
    [dispatch]
  );

  const onAdd = useCallback(() => {
    setIsAdding(true);
  }, []);
  const onCancelAdd = useCallback(() => {
    setIsAdding(false);
  }, []);

  const onEdit = useCallback((location: TLocation) => {
    setUpdatingLocation(location);
  }, []);
  const onCancelEdit = useCallback(() => {
    setUpdatingLocation(undefined);
  }, []);

  const onAddLocation = useCallback(
    (values: TLocation) => {
      dispatch(addLocation(values))
        .unwrap()
        .then(() => {
          onCancelAdd();
          user && getLocations(user?.cabinet?.cabinet_id);
        });
    },
    [dispatch, getLocations, onCancelAdd, user]
  );

  const onUpdateLocation = useCallback(
    (values: TLocation) => {
      dispatch(
        updateLocation({
          ...updatingLocation,
          ...values
        })
      )
        .unwrap()
        .then(() => {
          onCancelEdit();
          user && getLocations(user?.cabinet?.cabinet_id);
        });
    },
    [dispatch, getLocations, onCancelEdit, updatingLocation, user]
  );

  useEffect(() => {
    !locations && user && getLocations(user?.cabinet?.cabinet_id);
  }, [locations, getLocations, user]);

  useEffect(() => {
    return () => {
      dispatch(locationAddActions.clearState());
      dispatch(locationUpdateActions.clearState());
    };
  }, [dispatch]);

  return (
    <Wrapper>
      {isAdding ? (
        <>
          <LocationEditForm
            entities={user?.entities}
            onCancelEdit={onCancelAdd}
            onSubmit={onAddLocation}
            submitIsLoading={addIsLoading}
            submitError={addError}
          />
        </>
      ) : !!updatingLocation ? (
        <>
          <LocationEditForm
            location={updatingLocation}
            entities={user?.entities}
            onCancelEdit={onCancelEdit}
            onSubmit={onUpdateLocation}
            submitIsLoading={updateIsLoading}
            submitError={updateError}
          />
        </>
      ) : (
        <>
          <Header>
            <BasicTitle>{user?.cabinet?.name}</BasicTitle>

            {canAddUpdate && <Button onClick={onAdd}>Добавить адрес</Button>}
          </Header>

          {(locationsGroups || []).map((locationsGroups) => (
            <>
              <TypographyTitleNoMargin level={5}>
                {locationsGroups?.entity?.full_name}
              </TypographyTitleNoMargin>
              <AccordionGroup>
                {locationsGroups?.locations.map((location, index, self) => (
                  <>
                    <Collapse
                      ghost
                      items={[
                        {
                          key: location?.location_id,
                          label: location?.address,
                          children: (
                            <>
                              <LocationData
                                location={location}
                                entity={locationsGroups?.entity}
                              />
                            </>
                          ),
                          extra: canAddUpdate && (
                            <Button
                              icon={<EditOutlined />}
                              type="text"
                              onClick={() => onEdit(location)}
                              size="small"
                            />
                          )
                        }
                      ]}
                      size="large"
                    />
                    {index < self?.length - 1 && <Divider />}
                  </>
                ))}
              </AccordionGroup>
            </>
          ))}
        </>
      )}
    </Wrapper>
  );
};
