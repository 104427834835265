export type TCabinet = {
  cabinet_id: number; //id пользователя (таблица users)
  // is_seller: boolean;
  name: string;
  cabinetDefaultRole: ECabinetRole;
  cabinetRole: ECabinetRole;
};

export enum ECabinetRole {
  ADMIN = "ADMIN",
  BUYER = "BUYER",
  SELLER = "SELLER",
  MULTIPLE = "MULTIPLE"
}

export type TCabinetRoleWithId = {
  user_id: number;
  prevCabinetRole: ECabinetRole;
};
