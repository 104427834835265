import { PageableResponse, TOfferForSeller } from "types";
import { RequestPromise, apiRequest } from "utils/api";

export const getOffersForSeller = (
  data: TGetOffersForSellerRequest
): RequestPromise<TGetOffersForSellerResponse> => {
  return apiRequest.get(`/offers-for-sellers/get`, {
    params: data
  });
};

export type TGetOffersForSellerRequest = {
  search?: string;
  size: number;
  page: number;
  asc?: boolean;
  sort?: string;
};

export type TGetOffersForSellerResponse = PageableResponse<TOfferForSeller>;
