import { TLicenseType } from "types";
import { RequestPromise, apiRequest } from "utils/api";

export const updateLicenseType = (
  data: TUpdateLicenseTypeRequest
): RequestPromise<TUpdateLicenseTypeResponse> => {
  return apiRequest.post(`/license/type/update`, data);
};

export type TUpdateLicenseTypeRequest = TLicenseType;

export type TUpdateLicenseTypeResponse = TLicenseType;
