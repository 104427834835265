import { TCabinet } from "types";
import { RequestPromise, apiRequest } from "utils/api";

export const updateCabinet = (
  data: TUpdateCabinetRequest
): RequestPromise<TUpdateCabinetResponse> => {
  return apiRequest.post(`/cabinets/update`, data);
};

export type TUpdateCabinetRequest = TCabinet;

export type TUpdateCabinetResponse = TCabinet;
