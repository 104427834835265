import { TOfferForBuyer, TOfferForSeller, TPricesByPositionMod } from "types";

export const getBestPricesFromOffers = (
  offers?: (TOfferForBuyer | TOfferForSeller)[]
): TPricesByPositionMod | undefined => {
  if (!offers) {
    return;
  }

  const result: TPricesByPositionMod = {};

  offers?.forEach((offer) => {
    const offerId = offer?.offer_id;

    offer?.positions?.forEach((position) => {
      const posId = position?.application_position_id;
      const posPrice = position?.unit_price;
      const isAnalogue = position?.analogue_flag ? "analogue" : "original";

      if (posId && posPrice !== undefined) {
        if (!result[posId]) {
          result[posId] = {};
        }

        const curMinPrice = result[posId]?.[isAnalogue]?.price || Infinity;
        const curOfferId = result[posId]?.[isAnalogue]?.offerId;

        const isNewPriceLower = posPrice < curMinPrice;

        result[posId][isAnalogue] = {
          fromOwner: false,
          price: isNewPriceLower ? posPrice : curMinPrice,
          offerId: isNewPriceLower ? offerId : curOfferId
        };
      }
    });
  });

  return result;
};
