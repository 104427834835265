import { TChat } from "types";
import { RequestPromise, apiRequest } from "utils/api";

export const getChatsByApplicationId = (
  id: TGetChatsByApplicationIdRequest
): RequestPromise<TGetChatsByApplicationIdResponse> => {
  return apiRequest.get(`/chat/application${id ? `/${id}` : ""}`);
};

export type TGetChatsByApplicationIdRequest = number | void;

export type TGetChatsByApplicationIdResponse = TChat[];
