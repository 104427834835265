import React from "react";
import { Tabs, TabsProps } from "antd";
import {
  CompassOutlined,
  CreditCardOutlined,
  ProjectOutlined,
  ShopOutlined,
  TeamOutlined,
  UserOutlined
} from "@ant-design/icons";

import { useNavigate, useParams } from "react-router-dom";
import {
  AccountsTab,
  ColleaguesTab,
  CompaniesTab,
  LocationsTab,
  PersonalTab,
  ProjectsTab
} from "./components";
import { useAppSelector } from "store/store";
import { userSelectors } from "store/user";
import { ECabinetRole } from "types";

export const Profile = () => {
  const navigate = useNavigate();
  const { profile_tab } = useParams<{ profile_tab: string }>();

  const { currentRole } = useAppSelector(userSelectors.getState);

  const tabs: TabsProps["items"] = [
    /* TODO иконки */
    {
      key: "personal",
      label: "Профиль",
      icon: <UserOutlined />,
      children: <PersonalTab />
    },
    {
      key: "companies",
      label: "Мои компании",
      icon: <ShopOutlined />,
      children: <CompaniesTab />
    },
    {
      key: "colleagues",
      label: "Коллеги",
      icon: <TeamOutlined />,
      children: <ColleaguesTab />
    },
    ...(currentRole === ECabinetRole.BUYER
      ? [
          {
            key: "projects",
            label: "Проекты",
            icon: <ProjectOutlined />,
            children: <ProjectsTab />
          }
        ]
      : []),
    {
      key: "accounts",
      label: "Счета",
      icon: <CreditCardOutlined />,
      children: <AccountsTab />
    },
    ...(currentRole === ECabinetRole.BUYER
      ? [
          {
            key: "locations",
            label: "Адреса",
            icon: <CompassOutlined />,
            children: <LocationsTab />
          }
        ]
      : [])
  ];

  return (
    <>
      <Tabs
        items={tabs}
        destroyInactiveTabPane
        activeKey={profile_tab}
        onChange={(key) => {
          navigate(`../${key}`);
        }}
        size="small"
        style={{ marginTop: "-16px" }}
      />
    </>
  );
};
