import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  applicationsForSellerGetActions,
  applicationsForSellerGetSelectors
} from ".";
import * as api from "api/applications_for_sellers";
import { RequestError } from "types";
import { serializeError } from "serialize-error";
import { RootState } from "../../store";

export const getApplicationsForSeller = createAsyncThunk<
  api.TGetApplicationForSellersResponse,
  void,
  { state: RootState }
>(
  "applicationForSeller/getApplicationsForSeller",
  (payload, { dispatch, rejectWithValue, getState }) => {
    dispatch(applicationsForSellerGetActions.setLoading(true));
    dispatch(applicationsForSellerGetActions.setError(null));

    const { page, size, search, asc, sort } =
      applicationsForSellerGetSelectors.getState(getState());

    return api
      .getApplicationsForSellers({
        search,
        page: page - 1,
        size,
        asc,
        sort
      })
      .then(({ data }) => {
        dispatch(
          applicationsForSellerGetActions.setApplications(data?.content)
        );
        dispatch(
          applicationsForSellerGetActions.setTotalElements(data?.totalElements)
        );
        return data;
      })
      .catch((err: RequestError) => {
        dispatch(applicationsForSellerGetActions.setError(err));
        return rejectWithValue(serializeError(err));
      })
      .finally(() => {
        dispatch(applicationsForSellerGetActions.setLoading(false));
      });
  }
);
