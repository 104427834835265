import { TApplicationForSeller } from "types";
import { RequestPromise, apiRequest } from "utils/api";

export const getApplicationForSellerById = (
  id: TGetApplicationForSellerByIdRequest
): RequestPromise<TGetApplicationForSellerByIdResponse> => {
  return apiRequest.get(`/applications-for-sellers/${id}`);
};

export type TGetApplicationForSellerByIdRequest = number;

export type TGetApplicationForSellerByIdResponse = TApplicationForSeller;
