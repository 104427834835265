import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useAppDispatch, useAppSelector } from "store/store";

import { Wrapper } from "./Applications.styles";
import { Button, Typography } from "antd";
import {
  applicationsGetActions,
  applicationsGetSelectors
} from "store/applications/get";
import { getApplications } from "store/applications/get/thunk";
import { ApplicationEditForm, ApplicationsTable } from "components/common/old";
import { projectsByCabinetSelectors } from "store/projects/byCabinet";
import { userSelectors } from "store/user";
import { getProjectsByCabinet } from "store/projects/byCabinet/thunk";
import { useNavigate } from "react-router-dom";
import {
  applicationAddActions,
  applicationAddSelectors
} from "store/applications/add";
import { ECabinetRole, TApplication } from "types";
import { addApplication } from "store/applications/add/thunk";
import { CloseOutlined, PlusOutlined } from "@ant-design/icons";
import { accountsByCabinetIdSelectors } from "store/accounts/byCabinet";
import { getAccountsByCabinetId } from "store/accounts/byCabinet/thunk";
import { getColleaguesByCabinet } from "store/colleagues/byCabinet/thunk";
import { colleaguesByCabinetSelectors } from "store/colleagues/byCabinet";
import { SearchForm } from "./compoents";
import { PAGE_SIZES } from "constants/pagination";
import { entitiesByCabinetIdSelectors } from "store/entities/byCabinetId";
import { getEntitiesByCabinetId } from "store/entities/byCabinetId/thunk";
import { adminSelectors } from "store/admin";

export const Applications = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const { user, currentRole, isAdmin } = useAppSelector(userSelectors.getState);
  const {
    applications,
    totalElements,
    page,
    size,
    isLoading: applicationsLoading
  } = useAppSelector(applicationsGetSelectors.getState);
  const { projects } = useAppSelector(projectsByCabinetSelectors.getState);
  const { isLoading: addIsLoading, error: addError } = useAppSelector(
    applicationAddSelectors.getState
  );
  const { accounts } = useAppSelector(accountsByCabinetIdSelectors.getState);
  const { users } = useAppSelector(colleaguesByCabinetSelectors.getState);
  const { entities } = useAppSelector(entitiesByCabinetIdSelectors.getState);
  const { selectedCabinet, isAdminSeller } = useAppSelector(
    adminSelectors.getState
  );

  const [isAdding, setIsAdding] = useState(false);

  const cabinet_id = useMemo(
    () => (isAdmin ? selectedCabinet : user?.cabinet?.cabinet_id),
    [selectedCabinet, isAdmin, user]
  );

  const canAdd = useMemo(
    () => currentRole === ECabinetRole.BUYER || isAdmin,
    [currentRole, isAdmin]
  );

  const defaultApplication: TApplication = useMemo(
    () =>
      ({
        creator: user,
        approvements: [
          {
            approvement_type_id: 119,
            approver: users?.find((user) => user?.cabinet_role_id === 2)
          }
        ]
      }) as TApplication,
    [user, users]
  );

  const getApplicationsArr = useCallback(() => {
    dispatch(getApplications());
  }, [dispatch]);

  const onSearch = useCallback(
    (search?: string) => {
      dispatch(applicationsGetActions.setPage(1));
      dispatch(applicationsGetActions.setSearch(search));
      getApplicationsArr();
    },
    [dispatch, getApplicationsArr]
  );

  const getProjects = useCallback(() => {
    cabinet_id && dispatch(getProjectsByCabinet(cabinet_id));
  }, [cabinet_id, dispatch]);

  const getAccounts = useCallback(() => {
    cabinet_id && dispatch(getAccountsByCabinetId(cabinet_id));
  }, [cabinet_id, dispatch]);

  const getColleagues = useCallback(() => {
    cabinet_id && dispatch(getColleaguesByCabinet(cabinet_id));
  }, [cabinet_id, dispatch]);

  const getEntities = useCallback(() => {
    cabinet_id && dispatch(getEntitiesByCabinetId(cabinet_id));
  }, [cabinet_id, dispatch]);

  const onAdd = useCallback(() => {
    setIsAdding(true);
  }, []);
  const onCancelAdd = useCallback(() => {
    setIsAdding(false);
  }, []);

  const onAddApplication = useCallback(
    (values: TApplication) => {
      dispatch(addApplication(values))
        .unwrap()
        .then((application) => {
          onCancelAdd();
          navigate(`${application?.application_id}`);
        });
    },
    [dispatch, navigate, onCancelAdd]
  );

  const onPaginationChange = useCallback(
    (page: number, pageSize: number) => {
      if (pageSize === size) {
        dispatch(applicationsGetActions.setPage(page));
      } else {
        dispatch(applicationsGetActions.setPage(1));
        dispatch(applicationsGetActions.setSize(pageSize));
      }
      getApplicationsArr();
    },
    [dispatch, getApplicationsArr, size]
  );

  useEffect(() => {
    if (isAdmin ? isAdminSeller : currentRole === ECabinetRole.SELLER) {
      navigate("../availableapplications", { replace: true, relative: "path" });
    }
  }, [currentRole, isAdmin, isAdminSeller, navigate, user]);

  useEffect(() => {
    getApplicationsArr();
  }, [getApplicationsArr]);

  useEffect(() => {
    !projects && getProjects();
  }, [getProjects, projects]);

  useEffect(() => {
    !accounts && getAccounts();
  }, [accounts, getAccounts]);

  useEffect(() => {
    !users && getColleagues();
  }, [users, getColleagues]);

  useEffect(() => {
    !entities && getEntities();
  }, [entities, getEntities]);

  useEffect(() => {
    return () => {
      dispatch(applicationsGetActions.clearState());
      dispatch(applicationAddActions.clearState());
    };
  }, [dispatch]);

  return (
    <Wrapper>
      <Typography.Title level={3} style={{ margin: 0 }}>
        Заявки
      </Typography.Title>

      {canAdd && (
        <Button
          icon={isAdding ? <CloseOutlined /> : <PlusOutlined />}
          onClick={isAdding ? onCancelAdd : onAdd}
        >
          {isAdding ? "Отмена" : "Добавить"}
        </Button>
      )}

      {isAdding ? (
        <ApplicationEditForm
          application={defaultApplication}
          users={users}
          projects={projects}
          accounts={accounts}
          entities={entities}
          onSubmit={onAddApplication}
          isLoading={addIsLoading}
          error={addError}
        />
      ) : (
        <>
          <SearchForm
            onSearch={onSearch}
            isLoading={applicationsLoading}
            projects={projects}
            users={users}
            accounts={accounts}
          />

          <ApplicationsTable
            applications={applications}
            projects={projects}
            entities={entities}
            pagination={{
              current: page,
              onChange: onPaginationChange,
              pageSize: size,
              total: totalElements,
              showSizeChanger: true,
              pageSizeOptions: PAGE_SIZES
            }}
          />
        </>
      )}
    </Wrapper>
  );
};
