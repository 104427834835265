import { TLicenseType } from "types";
import { RequestPromise, apiRequest } from "utils/api";

export const deleteLicenseType = (
  id: TDeleteLicenseTypeRequest
): RequestPromise<TDeleteLicenseTypeResponse> => {
  return apiRequest.post(`/license/type/delete/${id}`, undefined);
};

export type TDeleteLicenseTypeRequest = number;

export type TDeleteLicenseTypeResponse = TLicenseType;
