import React, { FC, useCallback, useMemo } from "react";
import { Panel } from "..";
import { Button, Checkbox, Form, Input, Select } from "antd";
import { RequestError, TEntity, TPerson, TPersonEntity } from "types";
import { EntitiesSelector } from "./components";
import { ErrorAlert } from "..";
import { cabinetRoleIdOptions } from "constants/options";
import { PhoneMaskedInput } from "components/common/redesign";

type TUserEntitiyEditFormValues = Omit<TPersonEntity, "entity"> & {
  entity: number;
};
type TUserEditFormValues = Omit<TPerson, "entities"> & {
  entities?: TUserEntitiyEditFormValues[];
};

type TUserEditFormProps = {
  user?: TPerson;
  entities?: TEntity[];
  onSubmit: (values: TPerson) => void;
  isLoading: boolean;
  error: RequestError | null;
  withCabinetRoleId?: boolean;
};

export const UserEditForm: FC<TUserEditFormProps> = ({
  user,
  entities,
  onSubmit,
  isLoading,
  error,
  withCabinetRoleId
}) => {
  const initialValues: Partial<TUserEditFormValues> | undefined = useMemo(
    () =>
      user
        ? {
            ...user,
            entities: user?.entities?.map(({ entity, role_id }) => ({
              role_id,
              entity: entity?.entity_id
            })),
            ignore_notification: !user?.ignore_notification
          }
        : {
            ignore_notification: true,
            cabinet_role_id: 1
          },
    [user]
  );

  const onSubmitHandler = useCallback(
    (updUser: TUserEditFormValues) => {
      onSubmit({
        ...updUser,
        entities: updUser?.entities?.map(({ entity: entityId, role_id }) => ({
          role_id,
          entity: entities?.find(
            (entity) => entity?.entity_id === entityId
          ) as TEntity
        })),
        ignore_notification: !updUser?.ignore_notification
      });
    },
    [entities, onSubmit]
  );

  return (
    <Panel
      style={{ display: "flex", flexDirection: "column", alignItems: "center" }}
    >
      <Form
        name="basic"
        labelCol={{ span: 8 }}
        wrapperCol={{ span: 28 }}
        // style={{ maxWidth: "600px" }}
        onFinish={onSubmitHandler}
        // onFinishFailed={onFinishFailed}
        autoComplete="off"
        labelWrap
        colon={false}
        initialValues={initialValues}
      >
        <Form.Item<TUserEditFormValues>
          label="Фамилия"
          name="last_name"
          rules={[
            {
              required: true,
              whitespace: true,
              message: "Введите фамилию"
            }
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item<TUserEditFormValues>
          label="Имя"
          name="first_name"
          rules={[
            {
              required: true,
              whitespace: true,
              message: "Введите имя"
            }
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item<TUserEditFormValues> label="Отчество" name="middle_name">
          <Input />
        </Form.Item>

        <Form.Item<TUserEditFormValues>
          label="Email"
          name="email"
          rules={[
            {
              required: true,
              message: "Введите корректный email",
              type: "email"
            }
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item<TUserEditFormValues>
          label="Телефон"
          name="phone"
          rules={[
            {
              required: true,
              pattern: new RegExp(
                /^((7|8|\+7)[- ]?)(\(?\d{3}\)?[- ]?)?[\d\- ]{7,10}$/
              ),
              message: "Введите корректный номер"
            }
          ]}
        >
          <PhoneMaskedInput />
        </Form.Item>
        <Form.Item<TUserEditFormValues>
          label="Логин"
          name="login"
          rules={[
            {
              required: true,
              whitespace: true,
              message: "Введите логин"
            }
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item<TUserEditFormValues>
          label="Должность"
          name="position"
          rules={[
            {
              whitespace: true,
              message: "Введите должность"
            }
          ]}
        >
          <Input />
        </Form.Item>

        {withCabinetRoleId && (
          <Form.Item<TUserEditFormValues>
            label="Роль пользователя в кабинете"
            name="cabinet_role_id"
            rules={[
              {
                required: true,
                message: "Введите роль"
              }
            ]}
          >
            <Select options={cabinetRoleIdOptions} />
          </Form.Item>
        )}

        {entities && <EntitiesSelector entities={entities} />}

        <Form.Item<TUserEditFormValues>
          name="ignore_notification"
          valuePropName="checked"
          wrapperCol={{
            span: 16,
            offset: 8
          }}
        >
          <Checkbox>Получать уведомления</Checkbox>
        </Form.Item>

        <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
          <Button type="primary" htmlType="submit" loading={isLoading}>
            Сохранить
          </Button>
        </Form.Item>

        <ErrorAlert error={error} />
      </Form>
    </Panel>
  );
};
