import { TOfferForSeller, TOfferForSellerRequest } from "types";
import { RequestPromise, apiRequest } from "utils/api";

export const addOfferForSeller = (
  data: TAddOfferForSellerRequest
): RequestPromise<TAddOfferForSellerResponse> => {
  return apiRequest.post(`/offers-for-sellers/add`, data);
};

export type TAddOfferForSellerRequest = TOfferForSellerRequest;

export type TAddOfferForSellerResponse = TOfferForSeller;
