import { createAsyncThunk } from "@reduxjs/toolkit";
import { offersForSellerGetActions, offersForSellerGetSelectors } from ".";
import * as api from "api/offers_for_sellers";
import { RequestError } from "types";
import { serializeError } from "serialize-error";
import { RootState } from "../../store";

export const getOffersForSeller = createAsyncThunk<
  api.TGetOffersForSellerResponse,
  void,
  { state: RootState }
>(
  "offersForSeller/getOfferForSellers",
  (payload, { dispatch, rejectWithValue, getState }) => {
    dispatch(offersForSellerGetActions.setLoading(true));
    dispatch(offersForSellerGetActions.setError(null));

    const { page, size, search, asc, sort } =
      offersForSellerGetSelectors.getState(getState());

    return api
      .getOffersForSeller({
        search,
        page: page - 1,
        size,
        asc,
        sort
      })
      .then(({ data }) => {
        dispatch(offersForSellerGetActions.setOffersForSeller(data?.content));
        dispatch(
          offersForSellerGetActions.setTotalElements(data?.totalElements)
        );
        return data;
      })
      .catch((err: RequestError) => {
        dispatch(offersForSellerGetActions.setError(err));
        return rejectWithValue(serializeError(err));
      })
      .finally(() => {
        dispatch(offersForSellerGetActions.setLoading(false));
      });
  }
);
