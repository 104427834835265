import React, { FC, useCallback, useState } from "react";
import { Button, Form, Input, Radio, Select } from "antd";
import { RequestError, TEntity } from "types";
import { ErrorAlert } from "../../old";
import { CTABlock } from "./CompanyEditForm.styles";
import { DeleteFilled, LeftOutlined } from "@ant-design/icons";
import { ConfirmDelete } from "../ConfirmDelete/ConfirmDelete";
import { BasicTitle } from "../BasicTitle/BasicTitle";
import { entityOpfOptions } from "constants/options";
import { PhoneMaskedInput } from "../PhoneMaskedInput/PhoneMaskedInput";

type TCompanyEditFormProps = {
  entity?: TEntity;
  onCancelEdit: () => void;
  onSubmit: (values: TEntity) => void;
  submitIsLoading: boolean;
  submitError: RequestError | null;
  onDelete?: () => Promise<void> | undefined;
  deleteIsLoading?: boolean;
  deleteError?: RequestError | null;
};

export const CompanyEditForm: FC<TCompanyEditFormProps> = ({
  entity,
  onCancelEdit,
  onSubmit,
  submitIsLoading,
  submitError,
  onDelete,
  deleteIsLoading,
  deleteError
}) => {
  const [openDeleteModal, setOpenDeleteModal] = useState(false);

  const [form] = Form.useForm<TEntity>();

  const fullName = Form.useWatch("full_name", form);

  const onDeleteHandler = useCallback(() => {
    onDelete?.()?.then(() => {
      closeModal();
    });
  }, [onDelete]);

  const showModal = useCallback(() => {
    setOpenDeleteModal(true);
  }, []);

  const closeModal = () => {
    setOpenDeleteModal(false);
  };

  return (
    <>
      <BasicTitle>
        <Button icon={<LeftOutlined />} onClick={onCancelEdit} type="text" />
        {entity ? entity?.full_name : fullName}
      </BasicTitle>

      <Form
        name="company_edit"
        form={form}
        layout="vertical"
        requiredMark={false}
        style={{ maxWidth: "400px" }}
        onFinish={onSubmit}
        autoComplete="off"
        initialValues={entity}
      >
        <Form.Item<TEntity>
          label="ОПФ"
          name="opf"
          rules={[{ required: true, whitespace: true, message: "Введите ОПФ" }]}
        >
          <Select options={entityOpfOptions} />
        </Form.Item>

        <Form.Item<TEntity>
          label="Полное название"
          name="full_name"
          rules={[
            {
              required: true,
              whitespace: true,
              message: "Введите название"
            }
          ]}
        >
          <Input />
        </Form.Item>

        <Form.Item<TEntity>
          label="Сокращенное название"
          name="short_name"
          rules={[
            {
              required: true,
              whitespace: true,
              message: "Введите название"
            }
          ]}
        >
          <Input />
        </Form.Item>

        <Form.Item<TEntity>
          label="Юридический адрес"
          name="legal_address"
          rules={[
            {
              required: true,
              whitespace: true,
              message: "Введите юридический адрес"
            }
          ]}
        >
          <Input />
        </Form.Item>

        <Form.Item<TEntity> label="Физический адрес" name="physical_address">
          <Input />
        </Form.Item>

        <Form.Item<TEntity>
          label="Email"
          name="company_email"
          rules={[{ message: "Введите корректный email", type: "email" }]}
        >
          <Input />
        </Form.Item>

        <Form.Item<TEntity>
          label="Контактный телефон"
          name="company_phone"
          rules={[
            {
              pattern: new RegExp(
                /^((7|8|\+7)[- ]?)(\(?\d{3}\)?[- ]?)?[\d\- ]{7,10}$/
              ),
              message: "Введите корректный номер"
            }
          ]}
        >
          <PhoneMaskedInput />
        </Form.Item>

        <Form.Item<TEntity> label="Сайт компании" name="website">
          <Input />
        </Form.Item>

        <Form.Item<TEntity>
          label="ФИО бухгалтера"
          name="accountant_name"
          rules={[
            {
              required: true,
              whitespace: true,
              message: "Введите ФИО бухгалтера"
            }
          ]}
        >
          <Input />
        </Form.Item>

        <Form.Item<TEntity>
          label="ИНН"
          name="inn"
          rules={[
            {
              pattern: new RegExp(/^\d{10}$/),
              message: "ИНН должен состоять из 10 цифр"
            },
            {
              required: true,
              whitespace: true,
              message: "Введите ИНН"
            }
          ]}
        >
          <Input />
        </Form.Item>

        <Form.Item<TEntity>
          label="ОГРН"
          name="ogrn"
          rules={[
            {
              pattern: new RegExp(/^\d{8,13}$/),
              message: "ОГРН должен состоять из 8-13 цифр"
            },
            {
              required: true,
              whitespace: true,
              message: "Введите ОГРН"
            }
          ]}
        >
          <Input />
        </Form.Item>

        <Form.Item<TEntity>
          label="КПП"
          name="kpp"
          rules={[
            {
              pattern: new RegExp(/^\d{9}$/),
              message: "КПП должен состоять из 9 цифр"
            },
            {
              required: true,
              whitespace: true,
              message: "Введите КПП"
            }
          ]}
        >
          <Input />
        </Form.Item>

        <Form.Item<TEntity>
          label="Действующее"
          name="is_active"
          rules={[
            {
              required: true,
              message: "Выберите одну из опций"
            }
          ]}
        >
          <Radio.Group>
            <Radio value={true}>Да</Radio>
            <Radio value={false}>Нет</Radio>
          </Radio.Group>
        </Form.Item>

        <ErrorAlert error={submitError} />
      </Form>
      <CTABlock>
        <Button
          type="primary"
          loading={submitIsLoading}
          form="company_edit"
          htmlType="submit"
        >
          Сохранить
        </Button>

        {entity && (
          <Button
            icon={<DeleteFilled />}
            danger
            onClick={showModal}
            loading={deleteIsLoading}
          >
            Удалить юр. лицо
          </Button>
        )}
      </CTABlock>
      <ConfirmDelete
        open={openDeleteModal}
        onClose={closeModal}
        onDelete={onDeleteHandler}
        isLoading={deleteIsLoading}
        error={deleteError}
        title={`Удалить юр.лицо ${entity?.full_name}?`}
        subtitle={
          "Вы уверены, что хотите удалить это юр. лицо из вашего кабинета? Доступ к данным и документам, связанным с этим юр. лицом, будет прекращён. Подтвердить удаление?"
        }
      />
    </>
  );
};
