import { PageableResponse, TApplication } from "types";
import { RequestPromise, apiRequest } from "utils/api";

export const getApplications = (
  data: TGetApplicationsRequest
): RequestPromise<TGetApplicationsResponse> => {
  return apiRequest.get(`/applications/get`, {
    params: data
  });
};

export type TGetApplicationsRequest = {
  search?: string;
  size: number;
  page: number;
  asc?: boolean;
  sort?: string;
};

export type TGetApplicationsResponse = PageableResponse<TApplication>;
