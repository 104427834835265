import { TPerson } from "types";
import { RequestPromise, apiRequest } from "utils/api";

export const addUser = (
  data: TAddUserRequest
): RequestPromise<TAddUserResponse> => {
  return apiRequest.post(`/users/add`, data);
};

export type TAddUserRequest = Omit<Partial<TPerson>, "user_id">;

export type TAddUserResponse = TPerson;
