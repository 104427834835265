import { RequestPromise, apiRequest } from "utils/api";

export const logout = (): RequestPromise<TLogoutResponse> => {
  // return new Promise((resolve) => {
  //   setTimeout(() => {
  //     resolve({});
  //   }, 1000);
  // }) as never;

  return apiRequest.get(`/unauthorized/logout`);
};

export type TLogoutResponse = void;
