import React, { FC, useMemo } from "react";
import { TPerson } from "types";
import { EditOutlined } from "@ant-design/icons";
import { DescriptionsProps, Button } from "antd";
import { Wrapper } from "./UserData.styles";
import { cabinetRoleMapper, entityRoleIdMapper } from "utils/mappers";
import { Divider } from "../Divider/Divider";
import { DescriptionsCustom } from "../DescriptionsCustom/DescriptionsCustom";
import { ApplicationsTableSettings } from "../ApplicationsTableSettings/ApplicationsTableSettings";
import { OffersTableSettings } from "../OffersTableSettings/OffersTableSettings";

type TUserDataProps = {
  user: TPerson;
  onEdit: () => void;
  withEdit: boolean;
  fromPersonal?: boolean;
};

export const UserData: FC<TUserDataProps> = ({
  user,
  onEdit,
  withEdit,
  fromPersonal
}) => {
  const generalData: DescriptionsProps["items"] = useMemo(
    () => [
      { key: "position ", label: "Должность", children: user?.position },
      { key: "phone", label: "Контантный телефон", children: user?.phone },
      { key: "email", label: "Email", children: user?.email }
    ],
    [user]
  );
  const cabinetData: DescriptionsProps["items"] = useMemo(
    () => [
      {
        key: "name",
        label: "Кабинет",
        children: user?.cabinet?.name
      },
      {
        key: "cabinetRole",
        label: "Сторона",
        children: cabinetRoleMapper[user?.cabinet?.cabinetRole]
      },
      {
        key: "cabinet_role_name",
        label: "Роль",
        children: user?.cabinet_role_name
      }
    ],
    [user]
  );
  const entitiesData: DescriptionsProps["items"] = useMemo(
    () =>
      (user?.entities || []).map((entity) => ({
        key: entity?.entity?.entity_id,
        children: entityRoleIdMapper[entity?.role_id],
        label: entity?.entity?.short_name
      })),
    [user]
  );
  const settingsData: DescriptionsProps["items"] = useMemo(
    () => [
      {
        key: "ignore_notification",
        label: "Получать уведомления",
        children: !user?.ignore_notification ? "Да" : "Нет"
      },
      {
        key: "application_table_settings",
        label: "Настройки таблицы заявок",
        children: <ApplicationsTableSettings size="middle" />
      },
      {
        key: "offer_table_settings",
        label: "Настройки таблицы предложений",
        children: <OffersTableSettings size="middle" />
      }
    ],
    [user]
  );
  return (
    <Wrapper>
      <DescriptionsCustom
        items={generalData}
        title={
          <>
            {withEdit && (
              <Button
                icon={<EditOutlined onClick={onEdit} />}
                style={{ marginRight: 8 }}
              />
            )}
            Общая информация
          </>
        }
      />

      <Divider />

      <DescriptionsCustom items={cabinetData} title={"Кабинет"} />

      <Divider />

      <DescriptionsCustom items={entitiesData} title={"Юр. лица"} />

      <Divider />

      <DescriptionsCustom items={settingsData} title={"Настройки"} />
    </Wrapper>
  );
};
