import { MaskedInput } from "antd-mask-input";
import React, { ComponentProps, FC, SyntheticEvent, useCallback } from "react";

type PhoneMaskedInputProps = Partial<ComponentProps<typeof MaskedInput>>;

export const PhoneMaskedInput: FC<PhoneMaskedInputProps> = ({
  onChange,
  ...props
}) => {
  const onChangeHandler = useCallback(
    (
      e: SyntheticEvent<HTMLInputElement> & {
        maskedValue: string;
        unmaskedValue: string;
      }
    ) => {
      const currentValue = (e?.target as HTMLInputElement)?.value;
      const newValue = currentValue?.replace(/^\+\d/, "+7");
      if ((e?.target as HTMLInputElement)?.value) {
        (e!.target as HTMLInputElement)!.value = newValue;
      }

      onChange?.(e as any);
    },
    [onChange]
  );

  return (
    <MaskedInput
      {...props}
      mask={[{ mask: "+0 (000) 000-00-00", lazy: false }]}
      onChange={onChangeHandler}
    />
  );
};
