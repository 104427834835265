import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Header, Wrapper } from "../../Profile.styles";
import { useAppDispatch, useAppSelector } from "store/store";
import { userSelectors } from "store/user";
import { TEntity, TProject } from "types";
import {
  BasicTitle,
  Divider,
  ProjectData,
  ProjectEditForm,
  TypographyTitleNoMargin
} from "components/common/redesign";
import { Button, Collapse } from "antd";
import { AccordionGroup } from "./ProjectsTab.styles";
import { EditOutlined } from "@ant-design/icons";
import { projectAddActions, projectAddSelectors } from "store/projects/add";
import {
  projectUpdateActions,
  projectUpdateSelectors
} from "store/projects/update";
import {
  projectDeleteActions,
  projectDeleteSelectors
} from "store/projects/delete";
import { projectsByCabinetSelectors } from "store/projects/byCabinet";
import { getProjectsByCabinet } from "store/projects/byCabinet/thunk";
import { addProject } from "store/projects/add/thunk";
import { updateProject } from "store/projects/update/thunk";
import { deleteProject } from "store/projects/delete/thunk";
import { locationsByCabinetIdSelectors } from "store/locations/byCabinet";
import { getLocationsByCabinetId } from "store/locations/byCabinet/thunk";

type TGroupedProjectsWithAllowance = {
  projects: TProject[];
  entity?: TEntity;
  canUpdateDelete: boolean;
}[];

export const ProjectsTab = () => {
  const dispatch = useAppDispatch();

  const { user, isAdmin } = useAppSelector(userSelectors.getState);
  const { isLoading: addIsLoading, error: addError } = useAppSelector(
    projectAddSelectors.getState
  );
  const { isLoading: updateIsLoading, error: updateError } = useAppSelector(
    projectUpdateSelectors.getState
  );
  const { isLoading: deleteIsLoading, error: deleteError } = useAppSelector(
    projectDeleteSelectors.getState
  );
  const { projects } = useAppSelector(projectsByCabinetSelectors.getState);
  const { locations } = useAppSelector(locationsByCabinetIdSelectors.getState);

  const [isAdding, setIsAdding] = useState(false);
  const [updatingProject, setUpdatingProject] = useState<TProject | undefined>(
    undefined
  );

  const projectsGroups: TGroupedProjectsWithAllowance = useMemo(() => {
    const projectByEntityId: Record<number, TProject[]> = {};

    (projects || []).forEach((project) => {
      if (!projectByEntityId[project.entity_id]) {
        projectByEntityId[project.entity_id] = [];
      }
      projectByEntityId[project.entity_id]?.push(project);
    });

    return Object.keys(projectByEntityId).reduce(
      (acc: TGroupedProjectsWithAllowance, entity_id) => {
        const entity = (user?.entities || []).find(
          (entity) => entity?.entity?.entity_id === Number(entity_id)
        );

        const canUpdateDelete =
          user?.cabinet_role_id === 2 && (entity?.role_id || 0) > 3;

        return [
          ...acc,
          {
            projects: projectByEntityId[Number(entity_id)],
            entity: entity?.entity,
            canUpdateDelete
          }
        ];
      },
      []
    );
  }, [projects, user]);

  const canAdd = useMemo(
    () =>
      (user?.entities?.some((entity) => entity?.role_id >= 3) &&
        user?.cabinet_role_id === 2) ||
      isAdmin,
    [isAdmin, user?.cabinet_role_id, user?.entities]
  );

  const getProjects = useCallback(
    (cabinet_id: number) => {
      dispatch(getProjectsByCabinet(cabinet_id));
    },
    [dispatch]
  );

  const getLocations = useCallback(
    (cabinet_id: number) => {
      dispatch(getLocationsByCabinetId(cabinet_id));
    },
    [dispatch]
  );

  const onAdd = useCallback(() => {
    setIsAdding(true);
  }, []);
  const onCancelAdd = useCallback(() => {
    setIsAdding(false);
  }, []);

  const onEdit = useCallback((project: TProject) => {
    setUpdatingProject(project);
  }, []);
  const onCancelEdit = useCallback(() => {
    setUpdatingProject(undefined);
  }, []);

  const onAddProject = useCallback(
    (values: TProject) => {
      dispatch(addProject({ ...values }))
        .unwrap()
        .then(() => {
          onCancelAdd();
          user && getProjects(user?.cabinet?.cabinet_id);
        });
    },
    [dispatch, getProjects, onCancelAdd, user]
  );

  const onUpdateProject = useCallback(
    (values: TProject) => {
      dispatch(
        updateProject({
          ...updatingProject,
          ...values
        })
      )
        .unwrap()
        .then(() => {
          onCancelEdit();
          user && getProjects(user?.cabinet?.cabinet_id);
        });
    },
    [dispatch, getProjects, onCancelEdit, updatingProject, user]
  );

  const onDeleteProject = useCallback(() => {
    return (
      updatingProject &&
      dispatch(deleteProject(updatingProject?.project_id))
        .unwrap()
        .then(() => {
          onCancelEdit();
          user && getProjects(user?.cabinet?.cabinet_id);
        })
    );
  }, [dispatch, getProjects, onCancelEdit, updatingProject, user]);

  useEffect(() => {
    !projects && user && getProjects(user?.cabinet?.cabinet_id);
  }, [getProjects, projects, user]);

  useEffect(() => {
    !locations && user && getLocations(user?.cabinet?.cabinet_id);
  }, [getLocations, locations, user]);

  useEffect(() => {
    return () => {
      dispatch(projectAddActions.clearState());
      dispatch(projectUpdateActions.clearState());
      dispatch(projectDeleteActions.clearState());
    };
  }, [dispatch]);

  return (
    <Wrapper>
      {isAdding ? (
        <>
          <ProjectEditForm
            entities={user?.entities}
            locations={locations}
            onCancelEdit={onCancelAdd}
            onSubmit={onAddProject}
            submitIsLoading={addIsLoading}
            submitError={addError}
          />
        </>
      ) : !!updatingProject ? (
        <>
          <ProjectEditForm
            project={updatingProject}
            entities={user?.entities}
            locations={locations}
            onCancelEdit={onCancelEdit}
            onSubmit={onUpdateProject}
            submitIsLoading={updateIsLoading}
            submitError={updateError}
            onDelete={onDeleteProject}
            deleteIsLoading={deleteIsLoading}
            deleteError={deleteError}
          />
        </>
      ) : (
        <>
          <Header>
            <BasicTitle>{user?.cabinet?.name}</BasicTitle>

            {canAdd && <Button onClick={onAdd}>Добавить проект</Button>}
          </Header>

          {(projectsGroups || []).map((projectsGroup) => (
            <>
              <TypographyTitleNoMargin level={5}>
                {projectsGroup?.entity?.full_name}
              </TypographyTitleNoMargin>
              <AccordionGroup>
                {projectsGroup?.projects.map((project, index, self) => (
                  <>
                    <Collapse
                      ghost
                      items={[
                        {
                          key: project?.project_id,
                          label: project?.project_name,
                          children: (
                            <ProjectData
                              project={project}
                              entity={projectsGroup?.entity}
                              locations={locations}
                            />
                          ),
                          extra: projectsGroup?.canUpdateDelete && (
                            <Button
                              icon={<EditOutlined />}
                              type="text"
                              onClick={() => onEdit(project)}
                              size="small"
                            />
                          )
                        }
                      ]}
                      size="large"
                    />
                    {index < self?.length - 1 && <Divider />}
                  </>
                ))}
              </AccordionGroup>
            </>
          ))}
        </>
      )}
    </Wrapper>
  );
};
