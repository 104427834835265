import { TLicense } from "types";
import { RequestPromise, apiRequest } from "utils/api";

export const updateLicense = (
  data: TUpdateLicenseRequest
): RequestPromise<TUpdateLicenseResponse> => {
  return apiRequest.post(`/license/update`, data);
};

export type TUpdateLicenseRequest = TLicense;

export type TUpdateLicenseResponse = TLicense;
