import { TAccount } from "types";
import { RequestPromise, apiRequest } from "utils/api";

export const updateAccount = (
  data: TUpdateAccountRequest
): RequestPromise<TUpdateAccountResponse> => {
  return apiRequest.post(`/accounts/update`, data);
};

export type TUpdateAccountRequest = TAccount;

export type TUpdateAccountResponse = TAccount;
