import { TFileMeta } from "types";
import { RequestPromise, apiRequest } from "utils/api";

export const deleteFile = (
  id: TDeleteFileRequest
): RequestPromise<TDeleteFileResponse> => {
  return apiRequest.post(`/files/delete/${id}`, undefined);
};

export type TDeleteFileRequest = number; //file_meta_id

export type TDeleteFileResponse = TFileMeta;
