import React, { FC, useCallback, useState } from "react";

import {
  EFileType,
  RequestError,
  TApplication,
  TApplicationForSeller,
  TEntity,
  TFileMeta,
  TOfferForBuyer,
  TOfferForSeller,
  TPricesByPositionMod
} from "types";
import { Divider } from "../Divider/Divider";
import { Files } from "../Files/Files";
import { Button, Drawer } from "antd";
import { AccountData } from "../AccountData/AccountData";
import { CompanyData } from "../CompanyData/CompanyData";
import { CopyOutlined } from "@ant-design/icons";
import { OfferHeader, OfferPositionsTable, OfferProcess } from "./components";

export type TOfferDataProps = {
  offer: TOfferForSeller | TOfferForBuyer;
  application?: TApplication | TApplicationForSeller;
  buyerEntity?: TEntity;
  files?: TFileMeta[];
  getFiles?: () => void;
  canEditFiles?: boolean;

  onEdit?: () => void;
  onGoBack?: () => void;
  withEdit?: boolean;

  withStatusChange?: boolean;
  onUpdateStatus?: ({ key }: { key: string }) => void;
  statusIsLoading?: boolean;
  statusError?: RequestError | null;

  forSeller?: boolean;
  previewOnly?: boolean;
  onStepEdit?: (step: number) => void;

  withMessages?: boolean;

  bestPrices?: TPricesByPositionMod;
};

export const OfferData: FC<TOfferDataProps> = ({
  files,
  getFiles,
  canEditFiles,
  bestPrices,
  ...props
}) => {
  const [isBuyerAccountModalOpen, setIsBuyerAccountModalOpen] = useState(false);
  const [isBuyerEntityModalOpen, setIsBuyerEntityModalOpen] = useState(false);
  const [isSellerAccountModalOpen, setIsSellerAccountModalOpen] =
    useState(false);
  const [isSellerEntityModalOpen, setIsSellerEntityModalOpen] = useState(false);

  const [buyerAccountDataForCopy, setBuyerAccountDataForCopy] = useState<
    string | undefined
  >(undefined);
  const [buyerEntityDataForCopy, setBuyerEntityDataForCopy] = useState<
    string | undefined
  >(undefined);
  const [sellerAccountDataForCopy, setSellerAccountDataForCopy] = useState<
    string | undefined
  >(undefined);
  const [sellerEntityDataForCopy, setSellerEntityDataForCopy] = useState<
    string | undefined
  >(undefined);

  const openBuyerAccountModal = useCallback(() => {
    setIsBuyerAccountModalOpen(true);
  }, []);
  const closeBuyerAccountModal = useCallback(() => {
    setIsBuyerAccountModalOpen(false);
  }, []);
  const openBuyerEntityModal = useCallback(() => {
    setIsBuyerEntityModalOpen(true);
  }, []);
  const closeBuyerEntityModal = useCallback(() => {
    setIsBuyerEntityModalOpen(false);
  }, []);
  const openSellerAccountModal = useCallback(() => {
    setIsSellerAccountModalOpen(true);
  }, []);
  const closeSellerAccountModal = useCallback(() => {
    setIsSellerAccountModalOpen(false);
  }, []);
  const openSellerEntityModal = useCallback(() => {
    setIsSellerEntityModalOpen(true);
  }, []);
  const closeSellerEntityModal = useCallback(() => {
    setIsSellerEntityModalOpen(false);
  }, []);

  const onBuyerAccountCopy = useCallback(() => {
    buyerAccountDataForCopy &&
      navigator.clipboard.writeText(buyerAccountDataForCopy);
  }, [buyerAccountDataForCopy]);
  const onBuyerCompanyCopy = useCallback(() => {
    buyerEntityDataForCopy &&
      navigator.clipboard.writeText(buyerEntityDataForCopy);
  }, [buyerEntityDataForCopy]);
  const onSellerAccountCopy = useCallback(() => {
    sellerAccountDataForCopy &&
      navigator.clipboard.writeText(sellerAccountDataForCopy);
  }, [sellerAccountDataForCopy]);
  const onSellerCompanyCopy = useCallback(() => {
    sellerEntityDataForCopy &&
      navigator.clipboard.writeText(sellerEntityDataForCopy);
  }, [sellerEntityDataForCopy]);

  return (
    <>
      <OfferHeader
        {...props}
        onBuyerCompanyClick={openBuyerEntityModal}
        onBuyerAccountClick={openBuyerAccountModal}
        onSellerCompanyClick={openSellerEntityModal}
        onSellerAccountClick={openSellerAccountModal}
      />

      <Divider />

      <OfferPositionsTable
        positions={props?.offer?.positions}
        onStepEdit={props?.previewOnly ? props?.onStepEdit : undefined}
        bestPrices={bestPrices}
      />

      {!props.previewOnly && props?.forSeller && (
        <>
          <Divider />

          <OfferProcess creator={props?.offer?.creator} />
        </>
      )}

      {!props.previewOnly && (
        <>
          <Divider />

          <Files
            id={props?.offer?.offer_id}
            type={EFileType.OFFER}
            files={files}
            onFinish={getFiles}
            canEdit={canEditFiles}
          />
        </>
      )}

      <Drawer
        title="Счет поставщика"
        open={isSellerAccountModalOpen}
        onClose={closeSellerAccountModal}
        extra={
          sellerAccountDataForCopy && (
            <Button
              icon={<CopyOutlined />}
              onClick={onSellerAccountCopy}
              size="large"
            />
          )
        }
      >
        <AccountData
          account={props?.offer?.seller_account}
          entity={props?.offer?.seller_entity}
          vertical
          setDataForCopy={setSellerAccountDataForCopy}
        />
      </Drawer>
      {props?.application && (
        <Drawer
          title="Счет покупателя"
          open={isBuyerAccountModalOpen}
          onClose={closeBuyerAccountModal}
          extra={
            buyerAccountDataForCopy && (
              <Button
                icon={<CopyOutlined />}
                onClick={onBuyerAccountCopy}
                size="large"
              />
            )
          }
        >
          <AccountData
            account={props?.application?.buyer_account}
            entity={props?.buyerEntity}
            vertical
            setDataForCopy={setBuyerAccountDataForCopy}
          />
        </Drawer>
      )}
      <Drawer
        title="Юр. лицо поставщика"
        open={isSellerEntityModalOpen}
        onClose={closeSellerEntityModal}
        extra={
          sellerEntityDataForCopy && (
            <Button
              icon={<CopyOutlined />}
              onClick={onSellerCompanyCopy}
              size="large"
            />
          )
        }
      >
        <CompanyData
          entity={props?.offer?.seller_entity}
          vertical
          setDataForCopy={setSellerEntityDataForCopy}
        />
      </Drawer>
      {props?.buyerEntity && (
        <Drawer
          title="Юр. лицо покупателя"
          open={isBuyerEntityModalOpen}
          onClose={closeBuyerEntityModal}
          extra={
            buyerEntityDataForCopy && (
              <Button
                icon={<CopyOutlined />}
                onClick={onBuyerCompanyCopy}
                size="large"
              />
            )
          }
        >
          <CompanyData
            entity={props?.buyerEntity}
            vertical
            setDataForCopy={setBuyerEntityDataForCopy}
          />
        </Drawer>
      )}
    </>
  );
};
