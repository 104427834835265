import { TEntity } from "types";
import { RequestPromise, apiRequest } from "utils/api";

export const deleteEntity = (
  id: TDeleteEntityRequest
): RequestPromise<TDeleteEntityResponse> => {
  return apiRequest.post(`/entities/delete/${id}`, undefined);
};

export type TDeleteEntityRequest = number;

export type TDeleteEntityResponse = TEntity;
