import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Header, Wrapper } from "../../Profile.styles";
import { useAppDispatch, useAppSelector } from "store/store";
import { userSelectors } from "store/user";
import { TAccount, TEntity } from "types";
import {
  AccountData,
  AccountEditForm,
  BasicTitle,
  Divider,
  TypographyTitleNoMargin
} from "components/common/redesign";
import { Button, Collapse } from "antd";
import { AccordionGroup } from "./AccountsTab.styles";
import { EditOutlined } from "@ant-design/icons";

import { accountAddActions, accountAddSelectors } from "store/accounts/add";
import {
  accountUpdateActions,
  accountUpdateSelectors
} from "store/accounts/update";
import {
  accountDeleteActions,
  accountDeleteSelectors
} from "store/accounts/delete";
import { accountsByCabinetIdSelectors } from "store/accounts/byCabinet";
import { getAccountsByCabinetId } from "store/accounts/byCabinet/thunk";
import { addAccount } from "store/accounts/add/thunk";
import { updateAccount } from "store/accounts/update/thunk";
import { deleteAccount } from "store/accounts/delete/thunk";

type TGroupedAccounts = {
  accounts: TAccount[];
  entity?: TEntity;
}[];

export const AccountsTab = () => {
  const dispatch = useAppDispatch();

  const { user, isAdmin } = useAppSelector(userSelectors.getState);
  const { isLoading: addIsLoading, error: addError } = useAppSelector(
    accountAddSelectors.getState
  );
  const { isLoading: updateIsLoading, error: updateError } = useAppSelector(
    accountUpdateSelectors.getState
  );
  const { isLoading: deleteIsLoading, error: deleteError } = useAppSelector(
    accountDeleteSelectors.getState
  );
  const { accounts } = useAppSelector(accountsByCabinetIdSelectors.getState);

  const [isAdding, setIsAdding] = useState(false);
  const [updatingAccount, setUpdatingAccount] = useState<TAccount | undefined>(
    undefined
  );

  const accountsGroups: TGroupedAccounts = useMemo(() => {
    const accountByEntityId: Record<number, TAccount[]> = {};

    (accounts || []).forEach((account) => {
      if (!accountByEntityId[account.entity_id]) {
        accountByEntityId[account.entity_id] = [];
      }
      accountByEntityId[account.entity_id]?.push(account);
    });

    return Object.keys(accountByEntityId).reduce(
      (acc: TGroupedAccounts, entity_id) => {
        const entity = (user?.entities || []).find(
          (entity) => entity?.entity?.entity_id === Number(entity_id)
        )?.entity;

        return [
          ...acc,
          {
            accounts: accountByEntityId[Number(entity_id)],
            entity
          }
        ];
      },
      []
    );
  }, [accounts, user]);

  const canAddUpdateDelete = useMemo(
    () => user?.cabinet_role_id === 2 || isAdmin,
    [isAdmin, user]
  );

  const getAccounts = useCallback(
    (cabinet_id: number) => {
      dispatch(getAccountsByCabinetId(cabinet_id));
    },
    [dispatch]
  );

  const onAdd = useCallback(() => {
    setIsAdding(true);
  }, []);
  const onCancelAdd = useCallback(() => {
    setIsAdding(false);
  }, []);

  const onEdit = useCallback((account: TAccount) => {
    setUpdatingAccount(account);
  }, []);
  const onCancelEdit = useCallback(() => {
    setUpdatingAccount(undefined);
  }, []);

  const onAddAccount = useCallback(
    (values: TAccount) => {
      dispatch(addAccount(values))
        .unwrap()
        .then(() => {
          onCancelAdd();
          user && getAccounts(user?.cabinet?.cabinet_id);
        });
    },
    [dispatch, getAccounts, onCancelAdd, user]
  );

  const onUpdateAccount = useCallback(
    (values: TAccount) => {
      dispatch(
        updateAccount({
          ...updatingAccount,
          ...values
        })
      )
        .unwrap()
        .then(() => {
          onCancelEdit();
          user && getAccounts(user?.cabinet?.cabinet_id);
        });
    },
    [dispatch, getAccounts, onCancelEdit, updatingAccount, user]
  );

  const onDeleteAccount = useCallback(() => {
    return (
      updatingAccount &&
      dispatch(deleteAccount(updatingAccount?.account_id))
        .unwrap()
        .then(() => {
          onCancelEdit();
          user && getAccounts(user?.cabinet?.cabinet_id);
        })
    );
  }, [dispatch, getAccounts, onCancelEdit, updatingAccount, user]);

  useEffect(() => {
    !accounts && user && getAccounts(user?.cabinet?.cabinet_id);
  }, [accounts, getAccounts, user]);

  useEffect(() => {
    return () => {
      dispatch(accountAddActions.clearState());
      dispatch(accountUpdateActions.clearState());
      dispatch(accountDeleteActions.clearState());
    };
  }, [dispatch]);

  return (
    <Wrapper>
      {isAdding ? (
        <>
          <AccountEditForm
            entities={user?.entities}
            onCancelEdit={onCancelAdd}
            onSubmit={onAddAccount}
            submitIsLoading={addIsLoading}
            submitError={addError}
          />
        </>
      ) : !!updatingAccount ? (
        <>
          <AccountEditForm
            account={updatingAccount}
            entities={user?.entities}
            onCancelEdit={onCancelEdit}
            onSubmit={onUpdateAccount}
            submitIsLoading={updateIsLoading}
            submitError={updateError}
            onDelete={onDeleteAccount}
            deleteIsLoading={deleteIsLoading}
            deleteError={deleteError}
          />
        </>
      ) : (
        <>
          <Header>
            <BasicTitle>{user?.cabinet?.name}</BasicTitle>

            {canAddUpdateDelete && (
              <Button onClick={onAdd}>Добавить счет</Button>
            )}
          </Header>

          {(accountsGroups || []).map((accountsGroup) => (
            <>
              <TypographyTitleNoMargin level={5}>
                {accountsGroup?.entity?.full_name}
              </TypographyTitleNoMargin>
              <AccordionGroup>
                {accountsGroup?.accounts.map((account, index, self) => (
                  <>
                    <Collapse
                      ghost
                      items={[
                        {
                          key: account?.account_id,
                          label: account?.name,
                          children: (
                            <AccountData
                              account={account}
                              entity={accountsGroup?.entity}
                            />
                          ),
                          extra: canAddUpdateDelete && (
                            <Button
                              icon={<EditOutlined />}
                              type="text"
                              onClick={() => onEdit(account)}
                              size="small"
                            />
                          )
                        }
                      ]}
                      size="large"
                    />
                    {index < self?.length - 1 && <Divider />}
                  </>
                ))}
              </AccordionGroup>
            </>
          ))}
        </>
      )}
    </Wrapper>
  );
};
