import React from "react";
import { Route, Routes } from "react-router-dom";
import { useAppSelector } from "store/store";
import { userSelectors } from "store/user";
import {
  OffersForSeller,
  OffersForBuyer,
  OfferForSeller,
  OfferForBuyer
} from "./components";
import { ECabinetRole } from "types";

export const Offers = () => {
  const { currentRole } = useAppSelector(userSelectors.getState);

  return (
    <Routes>
      <Route
        index
        element={
          currentRole === ECabinetRole.SELLER ? (
            <OffersForSeller />
          ) : (
            <OffersForBuyer />
          )
        }
      />
      <Route
        path=":offer_id"
        element={
          currentRole === ECabinetRole.SELLER ? (
            <OfferForSeller />
          ) : (
            <OfferForBuyer />
          )
        }
      />
    </Routes>
  );
};
