import { TOfferForSeller, TOfferForSellerRequest } from "types";
import { RequestPromise, apiRequest } from "utils/api";

export const updateOfferForSeller = (
  data: TUpdateOfferForSellerRequest
): RequestPromise<TUpdateOfferForSellerResponse> => {
  return apiRequest.post(`/offers-for-sellers/update`, data);
};

export type TUpdateOfferForSellerRequest = TOfferForSellerRequest;

export type TUpdateOfferForSellerResponse = TOfferForSeller;
