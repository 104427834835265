import React, { FC, useCallback, useMemo, useState } from "react";
import {
  RequestError,
  TAccount,
  TApplicationForSeller,
  TEntity,
  TOfferForSeller,
  TOfferForSellerRequest,
  TOfferPosition,
  TPerson,
  TPricesByPositionMod
} from "types";
import { FormWrapper, Wrapper } from "./OfferEditForm.styles";
import { BasicTitle } from "../BasicTitle/BasicTitle";
import { Button, StepProps, Steps } from "antd";
import {
  InfoCircleOutlined,
  LeftOutlined,
  OrderedListOutlined,
  SmileOutlined
} from "@ant-design/icons";
import { GeneralTab, PositionsTab, PreviewTab } from "./components";
import { Dayjs } from "dayjs";
import { DEVICE } from "constants/breakpoints";
import { useMediaQuery } from "react-responsive";
import { toDisplayDate } from "utils/dates";

export type TOfferPositionFromValue = Omit<
  TOfferPosition,
  "unit_amount" | "unit_price" | "discount" | "vat"
> & {
  unit_amount: string;
  unit_price: string;
  discount: string;
  vat: string;
};
export type TOfferFormValues = Partial<
  Omit<TOfferForSellerRequest, "delivary_plan_ts" | "positions"> & {
    delivary_plan_ts?: Dayjs;
    positions?: TOfferPositionFromValue[];
  }
>;

export type TOfferEditFormTabProps = Omit<
  TOfferEditFormProps,
  "offer" | "onCancel" | "onSubmit"
> & {
  formData: Partial<TOfferForSellerRequest>;
  onSubmitStep: (values: Partial<TOfferForSellerRequest>) => void;
  onSubmitForm: (status_id: number) => void;
  onPrevStep: () => void;
  onStepEdit: (step: number) => void;
};

type TOfferEditFormProps = {
  offer?: TOfferForSeller;
  application?: TApplicationForSeller;
  entitiesForOffer?: TEntity[];
  user?: TPerson;
  accounts?: TAccount[];
  onSubmit: (values: TOfferForSellerRequest) => void;
  isLoading: boolean;
  error: RequestError | null;
  onCancel: () => void;

  bestPrices?: TPricesByPositionMod;
};

export const OfferEditForm: FC<TOfferEditFormProps> = ({
  offer,
  user,
  onCancel,
  onSubmit,
  ...props
}) => {
  const isLG = useMediaQuery({ query: DEVICE.LG });
  const isMD = useMediaQuery({ query: DEVICE.MD });

  const [formData, setFormData] = useState<Partial<TOfferForSellerRequest>>(
    offer
      ? {
          ...offer,
          seller_entity: offer?.seller_entity?.entity_id,
          seller_account: offer?.seller_account?.account_id
        }
      : {
          creator_id: user?.user_id,
          application_id: props?.application?.application_id
        }
  );
  const [step, setStep] = useState(0);

  const onNextStep = useCallback(() => {
    setStep((prev) => prev + 1);
  }, []);
  const onPrevStep = useCallback(() => {
    setStep((prev) => prev - 1);
  }, []);
  const onGoToStep = useCallback((step: number) => {
    setStep(step);
  }, []);

  const onSubmitStep = useCallback(
    (data: Partial<TOfferForSellerRequest>) => {
      setFormData((prev) => ({ ...prev, ...data }));
      onNextStep();
    },
    [onNextStep]
  );
  const onSubmitForm = useCallback(
    (status_id: number) => {
      onSubmit({ ...formData, status_id } as TOfferForSellerRequest);
    },
    [formData, onSubmit]
  );

  const steps: (StepProps & {
    content: React.ComponentType<TOfferEditFormTabProps>;
  })[] = useMemo(
    () => [
      {
        title: "Параметры предложения",
        icon: <InfoCircleOutlined />,
        content: GeneralTab
      },
      {
        title: "Позиции предложения",
        icon: <OrderedListOutlined />,
        content: PositionsTab
      },
      {
        title: "Завершение",
        icon: <SmileOutlined />,
        content: PreviewTab
      }
    ],
    []
  );

  const Tab = useMemo(() => steps[step].content, [step, steps]);

  return (
    <Wrapper>
      <BasicTitle>
        <Button icon={<LeftOutlined />} onClick={onCancel} type="text" />
        {offer
          ? `Предложение № ${offer?.offer_id} от ${toDisplayDate(offer?.create_ts)}`
          : `Создание предложения к заявке № ${props?.application?.application_id} - ${props?.application?.application_name}`}
      </BasicTitle>

      <Steps
        items={steps}
        current={step}
        size="small"
        progressDot={isLG}
        direction={isMD ? "vertical" : "horizontal"}
      />

      <FormWrapper>
        <Tab
          {...props}
          formData={formData}
          onSubmitForm={onSubmitForm}
          onPrevStep={onPrevStep}
          onSubmitStep={onSubmitStep}
          onStepEdit={onGoToStep}
        />
      </FormWrapper>
    </Wrapper>
  );
};
