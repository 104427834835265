import { TOfferForSeller } from "types";
import { RequestPromise, apiRequest } from "utils/api";

export const getOfferForSellerById = (
  id: TGetOfferForSellerByIdRequest
): RequestPromise<TGetOfferForSellerByIdResponse> => {
  return apiRequest.get(`/offers-for-sellers/${id}`);
};

export type TGetOfferForSellerByIdRequest = number;

export type TGetOfferForSellerByIdResponse = TOfferForSeller;
