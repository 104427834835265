import React, { FC } from "react";
import { Panel } from "components/common/old";
import { Button, Form, Input, Select } from "antd";
import { RequestError, TEntity } from "types";
import { entityOpfOptions } from "constants/options";
import { ErrorAlert } from "..";
import { PhoneMaskedInput } from "components/common/redesign";

type TCompanyEditFormProps = {
  entity?: TEntity;
  onSubmit: (values: TEntity) => void;
  isLoading: boolean;
  error: RequestError | null;
};

export const CompanyEditForm: FC<TCompanyEditFormProps> = ({
  entity,
  onSubmit,
  isLoading,
  error
}) => {
  return (
    <Panel
      style={{ display: "flex", flexDirection: "column", alignItems: "center" }}
    >
      <Form
        name="basic"
        labelCol={{ span: 8 }}
        wrapperCol={{ span: 28 }}
        // style={{ maxWidth: "600px" }}
        onFinish={onSubmit}
        // onFinishFailed={onFinishFailed}
        autoComplete="off"
        labelWrap
        colon={false}
        initialValues={entity}
      >
        <Form.Item<TEntity>
          label="ОПФ"
          name="opf"
          rules={[{ required: true, whitespace: true, message: "Введите ОПФ" }]}
        >
          <Select options={entityOpfOptions} />
        </Form.Item>
        <Form.Item<TEntity>
          label="Полное наименование"
          name="full_name"
          rules={[
            {
              required: true,
              whitespace: true,
              message: "Введите наименование"
            }
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item<TEntity>
          label="Краткое наименование"
          name="short_name"
          rules={[
            {
              required: true,
              whitespace: true,
              message: "Введите наименование"
            }
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item<TEntity>
          label="ИНН"
          name="inn"
          rules={[
            {
              pattern: new RegExp(/^\d{10}$/),
              message: "ИНН должен состоять из 10 цифр"
            },
            {
              required: true,
              whitespace: true,
              message: "Введите ИНН"
            }
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item<TEntity>
          label="ОГРН"
          name="ogrn"
          rules={[
            {
              pattern: new RegExp(/^\d{8,13}$/),
              message: "ОГРН должен состоять из 8-13 цифр"
            },
            {
              required: true,
              whitespace: true,
              message: "Введите ОГРН"
            }
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item<TEntity>
          label="КПП"
          name="kpp"
          rules={[
            {
              pattern: new RegExp(/^\d{9}$/),
              message: "КПП должен состоять из 9 цифр"
            },
            {
              required: true,
              whitespace: true,
              message: "Введите КПП"
            }
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item<TEntity>
          label="ФИО Бухгалтера"
          name="accountant_name"
          rules={[
            {
              required: true,
              whitespace: true,
              message: "Введите ФИО бухгалтера"
            }
          ]}
        >
          <Input />
        </Form.Item>

        {/* TODO что за руководитель? */}
        {/* <Form.Item<TEntity> label="ФИО Руководителя">
          <Input disabled />
        </Form.Item>
        <Form.Item<TEntity> label="Должность Руководителя">
          <Input disabled />
        </Form.Item> */}

        <Form.Item<TEntity>
          label="Email компании"
          name="company_email"
          rules={[{ message: "Введите корректный email", type: "email" }]}
        >
          <Input />
        </Form.Item>
        <Form.Item<TEntity>
          label="Телефон компании"
          name="company_phone"
          rules={[
            {
              pattern: new RegExp(
                /^((7|8|\+7)[- ]?)(\(?\d{3}\)?[- ]?)?[\d\- ]{7,10}$/
              ),
              message: "Введите корректный номер"
            }
          ]}
        >
          <PhoneMaskedInput />
        </Form.Item>
        <Form.Item<TEntity> label="Сайт компании" name="website">
          <Input />
        </Form.Item>

        <Form.Item<TEntity>
          label="Юридический адрес"
          name="legal_address"
          rules={[
            {
              required: true,
              whitespace: true,
              message: "Введите юридический адрес"
            }
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item<TEntity> label="Фактический адрес" name="physical_address">
          <Input />
        </Form.Item>

        <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
          <Button type="primary" htmlType="submit" loading={isLoading}>
            Сохранить
          </Button>
        </Form.Item>

        <ErrorAlert error={error} />
      </Form>
    </Panel>
  );
};
