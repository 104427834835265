import { TApplicationApprovement } from "types";
import { RequestPromise, apiRequest } from "utils/api";

export const updateApprovement = (
  data: TUpdateApprovementRequest
): RequestPromise<TUpdateApprovementResponse> => {
  return apiRequest.post(`/approvements/update`, data);
};

export type TUpdateApprovementRequest = {
  approvement_comment?: string;
  approvement_id: number;
  approvement_status_id: number;
};

export type TUpdateApprovementResponse = TApplicationApprovement;
