import { PayloadAction, combineReducers, createSlice } from "@reduxjs/toolkit";
import { TUserState } from "./types";

import { ECabinetRole, RequestError, TPerson } from "types";

import { loginReducer } from "./login";
import { registerReducer } from "./register";
import { restorePasswordReducer } from "./restorePassword";
import { setPasswordReducer } from "./setPassword";
import { logoutReducer } from "./logout";
import { updatePasswordReducer } from "./updatePassword";
import axios from "axios";

const initialState: TUserState = {
  user: undefined,
  isLoading: false,
  error: null,
  currentRole: undefined,
  isAdmin: false
};

export const UserSLice = createSlice({
  name: "user",
  initialState,
  reducers: {
    clearState: () => initialState,
    setUser: (state, { payload }: PayloadAction<TPerson>) => {
      state.user = payload;

      // if (payload.cabinet.cabinetRole === ECabinetRole.MULTIPLE) {
      if (!state.currentRole) {
        const prevRole = localStorage.getItem("prevRole");
        const { user_id, prevCabinetRole } = JSON.parse(prevRole || "{}");

        const cabinetDefaultRole =
          prevCabinetRole && user_id === payload?.user_id
            ? prevCabinetRole
            : payload.cabinet.cabinetDefaultRole;

        state.currentRole = cabinetDefaultRole;
        axios.defaults.headers["X-Select-Cabinet-Role-Header"] =
          cabinetDefaultRole;
      }

      state.isAdmin = payload?.cabinet?.cabinetRole === ECabinetRole.ADMIN;
      // }
    },
    clearUser: (state) => {
      state.user = initialState.user;
    },
    setCurrentRole: (state, { payload }: PayloadAction<ECabinetRole>) => {
      state.currentRole = payload;
      localStorage.setItem(
        "prevRole",
        JSON.stringify({
          user_id: state.user?.user_id,
          prevCabinetRole: payload
        })
      );
      axios.defaults.headers["X-Select-Cabinet-Role-Header"] = payload;
    },
    setLoading: (state, { payload }: PayloadAction<boolean>) => {
      state.isLoading = payload;
    },
    setError: (state, { payload }: PayloadAction<RequestError | null>) => {
      state.error = payload;
    }
  }
});

export const userActions = UserSLice.actions;
export const userStateReducer = UserSLice.reducer;

export const userReducer = combineReducers({
  state: userStateReducer,
  login: loginReducer,
  register: registerReducer,
  restorePassword: restorePasswordReducer,
  setPassword: setPasswordReducer,
  updatePassword: updatePasswordReducer,
  logout: logoutReducer
});

export * as userSelectors from "./selectors";
