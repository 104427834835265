import { TAccount } from "types";
import { RequestPromise, apiRequest } from "utils/api";

export const getAccountsByCabinetId = (
  id: TByCabinetIdAccountRequest
): RequestPromise<TByCabinetIdAccountResponse> => {
  return apiRequest.get(`/accounts/cabinet/${id}`);
};

export type TByCabinetIdAccountRequest = number;

export type TByCabinetIdAccountResponse = TAccount[];
