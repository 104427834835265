import { TCabinet } from "types";
import { RequestPromise, apiRequest } from "utils/api";

export const addCabinet = (
  data: TAddCabinetRequest
): RequestPromise<TAddCabinetResponse> => {
  return apiRequest.post(`/cabinets/add`, data);
};

export type TAddCabinetRequest = Omit<TCabinet, "cabinet_id">;

export type TAddCabinetResponse = TCabinet;
