import { TAccount } from "types";
import { RequestPromise, apiRequest } from "utils/api";

export const addAccount = (
  data: TAddAccountRequest
): RequestPromise<TAddAccountResponse> => {
  return apiRequest.post(`/accounts/add`, data);
};

export type TAddAccountRequest = Omit<TAccount, "account_id">;

export type TAddAccountResponse = TAccount;
