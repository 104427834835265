export const applicationUpdateStatusOptions: Record<number, number[]> = {
  92: [93, 122],
  93: [98],
  94: [96, 98],
  95: [92, 122],
  96: [98, 121],
  97: [],
  98: [92, 122],
  121: [97, 98],
  122: []
};
