import { TProject } from "types";
import { RequestPromise, apiRequest } from "utils/api";

export const deleteProject = (
  id: TDeleteProjectRequest
): RequestPromise<TDeleteProjectResponse> => {
  return apiRequest.post(`/project/delete/${id}`, undefined);
};

export type TDeleteProjectRequest = number;

export type TDeleteProjectResponse = TProject;
