import { TLicense } from "types";
import { RequestPromise, apiRequest } from "utils/api";

export const deleteLicense = (
  id: TDeleteLicenseRequest
): RequestPromise<TDeleteLicenseResponse> => {
  return apiRequest.post(`/license/delete/${id}`, undefined);
};

export type TDeleteLicenseRequest = number;

export type TDeleteLicenseResponse = TLicense;
