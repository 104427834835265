import { TProject } from "types";
import { RequestPromise, apiRequest } from "utils/api";

export const getProjectsByCabinet = (
  id: TGetProjectsByCabinetRequest
): RequestPromise<TGetProjectsByCabinetResponse> => {
  return apiRequest.get(`/project/cabinet/${id}`);
};

export type TGetProjectsByCabinetRequest = number;

export type TGetProjectsByCabinetResponse = TProject[];
