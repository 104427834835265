import { TPerson } from "types";
import { RequestPromise, apiRequest } from "utils/api";

export const updateUser = (
  data: TUpdateUserRequest
): RequestPromise<TUpdateUserResponse> => {
  return apiRequest.post(`/users/update`, data);
};

export type TUpdateUserRequest = TPerson;

export type TUpdateUserResponse = TPerson;
