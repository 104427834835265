import { TChatMessage } from "types";
import { RequestPromise, apiRequest } from "utils/api";

export const refreshChat = (
  data: TRefreshChatRequest
): RequestPromise<TRefreshChatResponse> => {
  return apiRequest.post(`/chat/refresh`, data);
};

export type TRefreshChatRequest = {
  chatId: number;
  lastMessageId: number;
};

export type TRefreshChatResponse = TChatMessage[];
