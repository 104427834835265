import { TEntity } from "types";
import { RequestPromise, apiRequest } from "utils/api";

export const getEntitiesByCabinetId = (
  id: TByCabinetIdEntityRequest
): RequestPromise<TByCabinetIdEntityResponse> => {
  return apiRequest.get(`/entities/cabinet/${id}`);
};

export type TByCabinetIdEntityRequest = number;

export type TByCabinetIdEntityResponse = TEntity[];
