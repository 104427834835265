import { combineReducers } from "@reduxjs/toolkit";
import { ApplicationForSellerByIdReducer } from "./byId";
import { applicationForSellerBestPricesByIdReducer } from "./bestPricesById";
import { applicationsForSellerGetReducer } from "./get";

export const applicationsForSellerReducer = combineReducers({
  byId: ApplicationForSellerByIdReducer,
  bestPricesById: applicationForSellerBestPricesByIdReducer,
  get: applicationsForSellerGetReducer
});
