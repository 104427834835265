import { RequestPromise, apiRequest } from "utils/api";

export const updatePassword = (
  data: TUpdatePasswordRequest
): RequestPromise<TUpdatePasswordResponse> => {
  return apiRequest.post(`/unauthorized/updatepass`, data);
};

export type TUpdatePasswordResponse = void;

export type TUpdatePasswordRequest = {
  email: string;
  newpassword: string;
};
