import { TCabinet } from "types";
import { RequestPromise, apiRequest } from "utils/api";

export const deleteCabinet = (
  id: TDeleteCabinetRequest
): RequestPromise<TDeleteCabinetResponse> => {
  return apiRequest.post(`/cabinets/delete/${id}`, undefined);
};

export type TDeleteCabinetRequest = number;

export type TDeleteCabinetResponse = TCabinet;
