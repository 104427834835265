import { TEntity } from "types";
import { RequestPromise, apiRequest } from "utils/api";

export const updateEntity = (
  data: TUpdateEntityRequest
): RequestPromise<TUpdateEntityResponse> => {
  return apiRequest.post(`/entities/update`, data);
};

export type TUpdateEntityRequest = TEntity;

export type TUpdateEntityResponse = TEntity;
