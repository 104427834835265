import React, { FC, useCallback, useState } from "react";
import {
  Badge,
  Button,
  Col,
  DatePicker,
  Form,
  Input,
  Popover,
  Row,
  Select
} from "antd";
import {
  applicationPositionCategoryIdOptions,
  applicationStatusIdOptions,
  projectRegionIdOptions
} from "constants/options";
import { Dayjs } from "dayjs";
import { DATE_FORMAT } from "constants/dates";
import {
  FiltersBlock,
  FiltersButton,
  FiltersContainer,
  IdInput,
  SearchButton,
  SearchRow
} from "./SearchForm.styles";
import { FilterOutlined, SearchOutlined } from "@ant-design/icons";
import {
  ApplicationsTableSettings,
  TypographyTitleNoMargin
} from "components/common/redesign";
import { useMediaQuery } from "react-responsive";
import { DEVICE } from "constants/breakpoints";

type TSearchFormProps = {
  onSearch: (value?: string) => void;
  isLoading: boolean;
};

type TSearchFormValues = {
  name: string;
  creationDt: [Dayjs, Dayjs];
  statusId: number;
  resourceCategoryId: number;
  applicationRegionId: number;
};

export const SearchForm: FC<TSearchFormProps> = ({ onSearch, isLoading }) => {
  const isLG = useMediaQuery({ query: DEVICE.LG });
  const isSM = useMediaQuery({ query: DEVICE.SM });

  const [form] = Form.useForm<TSearchFormValues>();

  const [id, setId] = useState<string | undefined>(undefined);

  const hasFilters = !!Object.values(form.getFieldsValue())?.filter((i) => i)
    ?.length;

  const onIdChange = useCallback((e: React.FormEvent<HTMLInputElement>) => {
    setId(e?.currentTarget?.value);
  }, []);

  const onSearchHandler = useCallback(() => {
    const {
      statusId,
      name,
      creationDt,
      resourceCategoryId,
      applicationRegionId
    } = form.getFieldsValue();

    onSearch(
      [
        id ? `id:${id}` : undefined,

        statusId ? `status.id:${statusId}` : undefined,
        name ? `name:${name}` : undefined,

        creationDt
          ? [
              `creationDt>${creationDt[0].startOf("day").valueOf()}`,
              `creationDt<${creationDt[1].endOf("day").valueOf()}`
            ].join(",")
          : undefined,
        resourceCategoryId
          ? `applicationPositions.resourceCategory.id:${resourceCategoryId}`
          : undefined,
        applicationRegionId
          ? `project.region.id:${applicationRegionId}`
          : undefined
      ]
        .filter((item) => !!item)
        .join(",")
    );
  }, [form, id, onSearch]);

  const onClearForm = useCallback(() => {
    form.resetFields();
  }, [form]);

  return (
    <>
      <SearchRow>
        <IdInput
          value={id}
          onChange={onIdChange}
          allowClear
          placeholder="ID заявки"
          prefix={<SearchOutlined />}
          size="large"
        />
        <FiltersBlock>
          <SearchButton
            loading={isLoading}
            form="applications_for_seller_search"
            onClick={onSearchHandler}
            size="large"
          >
            Поиск
          </SearchButton>
          <Popover
            trigger="click"
            placement="bottomRight"
            arrow={false}
            content={
              <FiltersContainer>
                <TypographyTitleNoMargin level={5}>
                  Фильтры
                </TypographyTitleNoMargin>
                <Form
                  name="applications_for_seller_search"
                  form={form}
                  // labelCol={{ span: 8 }}
                  // wrapperCol={{ span: 28 }}
                  // onFinish={onSearchHandler}
                  // onFinishFailed={onFinishFailed}
                  // autoComplete="off"
                  labelWrap
                  // colon={false}
                  layout="vertical"
                  className="withRows"
                  size="large"
                >
                  <Row gutter={isSM ? 12 : 24} justify="space-between">
                    <Col span={isSM ? 24 : 12}>
                      <Form.Item<TSearchFormValues>
                        label="Статус"
                        name="statusId"
                        layout="vertical"
                      >
                        <Select
                          options={applicationStatusIdOptions}
                          allowClear
                        />
                      </Form.Item>
                    </Col>
                    <Col span={isSM ? 24 : 12}>
                      <Form.Item<TSearchFormValues>
                        label="Наименование заявки"
                        name="name"
                      >
                        <Input allowClear />
                      </Form.Item>
                    </Col>

                    <Col span={isSM ? 24 : 12}>
                      <Form.Item<TSearchFormValues>
                        label="Дата создания"
                        name="creationDt"
                      >
                        <DatePicker.RangePicker
                          format={DATE_FORMAT}
                          style={{ width: "100%" }}
                        />
                      </Form.Item>
                    </Col>
                    <Col span={isSM ? 24 : 12}>
                      <Form.Item<TSearchFormValues>
                        label="Товарные категории"
                        name="resourceCategoryId"
                      >
                        <Select
                          options={applicationPositionCategoryIdOptions}
                          allowClear
                        />
                      </Form.Item>
                    </Col>

                    <Col span={isSM ? 24 : 12}>
                      <Form.Item<TSearchFormValues>
                        label="Регион"
                        name="applicationRegionId"
                      >
                        <Select options={projectRegionIdOptions} allowClear />
                      </Form.Item>
                    </Col>
                    <Col span={isSM ? 24 : 12} />

                    <Col span={isSM ? 24 : undefined}>
                      <Form.Item>
                        <Button
                          type="text"
                          style={{ color: "#BFBFBF", width: "100%" }}
                          loading={isLoading}
                          form="applications_for_seller_search"
                          onClick={onClearForm}
                        >
                          Очистить все
                        </Button>
                      </Form.Item>
                    </Col>

                    <Col span={isSM ? 24 : 8}>
                      <Form.Item>
                        <Button
                          type="primary"
                          style={{ width: "100%" }}
                          loading={isLoading}
                          form="applications_for_seller_search"
                          onClick={onSearchHandler}
                        >
                          Применить
                        </Button>
                      </Form.Item>
                    </Col>
                  </Row>
                </Form>
              </FiltersContainer>
            }
          >
            <Badge dot={hasFilters}>
              <FiltersButton icon={<FilterOutlined />} size="large">
                {isLG ? undefined : "Фильтры"}
              </FiltersButton>
            </Badge>
          </Popover>

          <ApplicationsTableSettings />
        </FiltersBlock>
      </SearchRow>
    </>
  );
};
