import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Link, Outlet, useLocation, useNavigate } from "react-router-dom";
import {
  Content,
  ContentColumn,
  CredText,
  CTABlock,
  Darkener,
  Footer,
  Header,
  LayoutStyled,
  Logo,
  LogoLink,
  MainWrapper,
  MenuColumn,
  MenuStyled,
  ProfileBlock,
  ProfilePositionText,
  ProfileTexts,
  StyledButton
} from "./AuthorizedLayout.styles";
import logoNamed from "images/logoNamed.png";
import { Button, Dropdown, MenuProps } from "antd";
import {
  CheckSquareFilled,
  CrownFilled,
  HomeFilled,
  MailFilled,
  MenuOutlined,
  QuestionCircleOutlined,
  ShopFilled,
  SmileFilled,
  TagsFilled,
  UserSwitchOutlined
} from "@ant-design/icons";
import { useMediaQuery } from "react-responsive";
import { DEVICE } from "constants/breakpoints";
import { useAppDispatch, useAppSelector } from "store/store";
import { userActions, userSelectors } from "store/user";
import { BasicTitle, ProfileAvatar } from "components/common/redesign";
import { getApplicationsTableSettings } from "store/settings/applicationsTable/thunk";
import { getOffersTableSettings } from "store/settings/offersTable/thunk";
import { ECabinetRole } from "types";

export const AuthorizedLayout = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useAppDispatch();

  const isXL = useMediaQuery({ query: DEVICE.XL });
  const isLG = useMediaQuery({ query: DEVICE.LG });
  const isMD = useMediaQuery({ query: DEVICE.MD });
  const isSM = useMediaQuery({ query: DEVICE.SM });

  const { user, currentRole, isAdmin } = useAppSelector(userSelectors.getState);

  const [menuOpened, setMenuOpened] = useState(true);
  const [mobileMenuOpened, setMobileMenuOpened] = useState(false);

  const contentNotScrollable = useMemo(
    () => location.pathname.includes("messages"),
    [location.pathname]
  );

  const toggleMenu = useCallback(() => {
    setMenuOpened((prev) => !prev);
  }, []);
  const toggleMobileMenu = useCallback((e: React.MouseEvent) => {
    e.stopPropagation();
    setMobileMenuOpened((prev) => !prev);
  }, []);
  const closeMobileMenu = useCallback(() => {
    setMobileMenuOpened(false);
  }, []);
  const onSwitchCabinerRole = useCallback(() => {
    if (currentRole === ECabinetRole.SELLER) {
      dispatch(userActions.setCurrentRole(ECabinetRole.BUYER));
    } else if (currentRole === ECabinetRole.BUYER) {
      dispatch(userActions.setCurrentRole(ECabinetRole.SELLER));
    }
  }, [currentRole, dispatch]);

  const menuItems: MenuProps["items"] = useMemo(
    () => [
      /* TODO другие иконки */
      ...(isSM
        ? [
            {
              key: "messages",
              icon: <MailFilled />,
              label: "Сообщения"
              // extra: <Badge count={0} showZero />
            },
            // {
            //   key: "notifications",
            //   icon: <BellFilled />,
            //   label: "Уведомления",
            //   extra: <Badge count={0} showZero />
            // },
            { key: "profile", icon: <SmileFilled />, label: "Профиль" },
            {
              type: "divider"
            } as Required<MenuProps>["items"][number]
          ]
        : []),
      { key: "", icon: <HomeFilled />, label: "Домашняя страница" },
      { key: "applications", icon: <ShopFilled />, label: "Заявки" },
      { key: "offers", icon: <TagsFilled />, label: "Предложения" },
      {
        key: "approvements",
        icon: <CheckSquareFilled />,
        label: "Согласования"
      },
      {
        type: "divider"
      },
      ...(user?.cabinet?.cabinetRole === ECabinetRole.MULTIPLE
        ? [
            {
              key: "switch",
              icon: <UserSwitchOutlined />,
              label: `В статус ${currentRole === ECabinetRole.SELLER ? "покупателя" : "поставщика"}`,
              onClick: onSwitchCabinerRole
            }
          ]
        : []),
      {
        key: "help",
        icon: <QuestionCircleOutlined />,
        label: "Поддержка"
      },
      ...(isAdmin
        ? [
            {
              key: "admin",
              icon: <CrownFilled />,
              label: "Администратор"
            }
          ]
        : [])
    ],
    [
      currentRole,
      isAdmin,
      isSM,
      onSwitchCabinerRole,
      user?.cabinet?.cabinetRole
    ]
  );

  const curMenuItem = useMemo(
    () => location.pathname.split("/")[1],
    [location]
  );

  useEffect(() => {
    dispatch(getApplicationsTableSettings());
    dispatch(getOffersTableSettings());
  }, [dispatch]);

  return (
    <LayoutStyled>
      <Header onClick={isLG ? closeMobileMenu : undefined}>
        <LogoLink to="/">
          <Logo src={logoNamed} />
        </LogoLink>
        {!isSM ? (
          <CTABlock>
            {/* TODO другие иконки */}
            <Link to="/messages">
              {/* <Badge count={0} showZero offset={[0, 10]}> */}
              <StyledButton
                icon={<MailFilled />}
                type="text"
                size={isXL ? "middle" : "large"}
              />
              {/* </Badge> */}
            </Link>
            {/* <Link to="/notifications">
              <Badge count={0} showZero offset={[0, 10]}>
                <StyledButton
                  icon={<BellFilled />}
                  type="text"
                  size={isXL ? "middle" : "large"}
                />
              </Badge>
            </Link> */}
            <Link to="/profile">
              <ProfileBlock>
                <ProfileAvatar
                  size={40}
                  shape="square"
                  isSeller={currentRole === ECabinetRole.SELLER}
                >
                  {user?.last_name?.[0]}
                  {user?.first_name?.[0]}
                </ProfileAvatar>
                {!isMD && (
                  <ProfileTexts>
                    <BasicTitle level={5}>
                      {user?.last_name} {user?.first_name}
                    </BasicTitle>
                    <ProfilePositionText>{user?.position}</ProfilePositionText>
                  </ProfileTexts>
                )}
              </ProfileBlock>
            </Link>
          </CTABlock>
        ) : (
          <Dropdown
            menu={{
              items: menuItems,
              selectedKeys: [curMenuItem],
              onClick: ({ key }) => {
                closeMobileMenu();
                if (key !== "switch") {
                  navigate(`../${key}`);
                }
              }
            }}
            open={mobileMenuOpened}
            placement="bottomRight"
            trigger={["click"]}
            overlayClassName={"ant-dd-menu-custom"}
          >
            <Button
              icon={<MenuOutlined />}
              onClick={toggleMobileMenu}
              type="primary"
              size="large"
            />
          </Dropdown>
        )}
      </Header>
      <MainWrapper>
        {!isSM && (
          <>
            <MenuColumn $opened={!isLG && menuOpened}>
              {!isLG ? (
                <Button
                  icon={<MenuOutlined />}
                  onClick={toggleMenu}
                  type="primary"
                  size="large"
                />
              ) : (
                <Dropdown
                  menu={{
                    items: menuItems,
                    selectedKeys: [curMenuItem],
                    onClick: ({ key }) => {
                      closeMobileMenu();
                      if (key !== "switch") {
                        navigate(`../${key}`);
                      }
                    }
                  }}
                  open={mobileMenuOpened}
                  placement="bottomLeft"
                  trigger={["click"]}
                  overlayClassName={"ant-dd-menu-custom"}
                >
                  <Button
                    icon={<MenuOutlined />}
                    onClick={toggleMobileMenu}
                    type="primary"
                    size="large"
                  />
                </Dropdown>
              )}
              <MenuStyled
                selectedKeys={[curMenuItem]}
                mode="inline"
                inlineCollapsed={!isLG ? !menuOpened : true}
                items={menuItems}
                onClick={({ key }) => {
                  if (key !== "switch") {
                    navigate(`../${key}`);
                  }
                }}
              />
            </MenuColumn>
          </>
        )}
        {isLG && mobileMenuOpened && <Darkener onClick={closeMobileMenu} />}

        <ContentColumn>
          <Content $notScrollable={contentNotScrollable}>
            <Outlet />
          </Content>
          <Footer $opened={menuOpened}>
            <CredText>Contractor DEV @{new Date().getFullYear()}</CredText>
          </Footer>
        </ContentColumn>
      </MainWrapper>

      {/* <ImageWrapper>
        <Image src={unauthorizedBg} />
        <TextBlock>
          <Title level={2}>Авторизованный лейаут</Title>
          <Subtitle level={4}>
            Продавцы и закупщики на одной волне для взаимного роста.
          </Subtitle>
        </TextBlock>
      </ImageWrapper>
      <MainWrapper>
        <Header>
          <LogoLink to="/">
            <Logo src={logoNamed} />
          </LogoLink>
        </Header>
        <Main>
          <MainContent>
          </MainContent>
          <Footer>
            <CredText>Contractor DEV @{new Date().getFullYear()}</CredText>
          </Footer>
        </Main>
      </MainWrapper> */}
    </LayoutStyled>
  );
};
