import { TChat } from "types";
import { RequestPromise, apiRequest } from "utils/api";

export * from "./application";
export * from "./get";
export * from "./refresh";
export * from "./send";

export const getChats = (): RequestPromise<TGetChatsResponse> => {
  return apiRequest.get(`/chat`);
};

export type TGetChatsRequest = void;

export type TGetChatsResponse = TChat[];
