import { RequestPromise, apiRequest } from "utils/api";

export const sendChatMessage = (
  data: TSendChatMessageRequest
): RequestPromise<TSendChatMessageResponse> => {
  return apiRequest.post(`/chat/send`, data);
};

export type TSendChatMessageRequest = {
  chatId: number;
  message: string;
};

export type TSendChatMessageResponse = void;
