import React, { FC, useCallback, useMemo, useState } from "react";
import {
  TOfferEditFormTabProps,
  TOfferFormValues,
  TOfferPositionFromValue
} from "../../OfferEditForm";
import {
  Button,
  Checkbox,
  Dropdown,
  Empty,
  Form,
  FormListFieldData,
  Input,
  InputNumber,
  Select,
  Space,
  Table,
  TableColumnsType
} from "antd";
import {
  CTABlock,
  NavButton,
  TableScrollWrapper,
  TableWrapper
} from "../../OfferEditForm.styles";
import {
  DownOutlined,
  MinusOutlined,
  OrderedListOutlined,
  PlusOutlined
} from "@ant-design/icons";
import {
  applicationPositionCategoryIdOptions,
  applicationPositionUnitTypeIdOptions,
  vatOptions
} from "constants/options";
import { Divider, TypographyTitleNoMargin } from "components/common/redesign";
import { ApplicationPositionsTable } from "components/common/redesign/ApplicationData/components";
import { TApplicationPosition } from "types";
import { countPrice } from "utils/countPrice";

export const PositionsTab: FC<TOfferEditFormTabProps> = ({
  formData,
  application,
  onSubmitStep,
  onPrevStep,
  bestPrices
}) => {
  const [form] = Form.useForm<TOfferFormValues>();

  const [discount, setDiscount] = useState<number | undefined>(undefined);

  // const positions = Form.useWatch("positions", form);

  // const { price, priceVAT } = useMemo(
  //   () => countOfferPositionsPrice(positions),
  //   [positions]
  // );

  const positionsOptions = useMemo(
    () =>
      (application?.positions || []).map((position) => ({
        label: position?.position_name,
        value: position?.position_id
      })),

    [application]
  );

  const initialValues: TOfferFormValues = useMemo(() => {
    const { positions } = formData;

    return {
      positions: positions?.map((position) => ({
        ...position,
        unit_amount: position?.unit_amount?.toString()?.replaceAll(".", ","),
        unit_price: position?.unit_price?.toString()?.replaceAll(".", ","),
        discount: position?.discount?.toString()?.replaceAll(".", ","),
        vat: position?.vat?.toString()?.replaceAll(".", ",")
      }))
    };
  }, [formData]);

  const onSubmitHandler = useCallback(
    (offer: TOfferFormValues) => {
      const { positions } = offer;
      onSubmitStep({
        positions: positions?.map((position) => ({
          ...position,
          unit_amount: Number(
            position?.unit_amount?.toString()?.replaceAll(",", ".")
          ),
          unit_price: Number(
            position?.unit_price?.toString()?.replaceAll(",", ".")
          ),
          discount: Number(
            position?.discount?.toString()?.replaceAll(",", ".")
          ),
          vat: Number(position?.vat?.toString()?.replaceAll(",", "."))
        }))
      });
    },
    [onSubmitStep]
  );

  const onClearAuthentity = useCallback(
    (name: number) => {
      form.setFieldValue(["positions", name, "resource_code_id"], undefined);
      form.setFieldValue(["positions", name, "analogue_flag"], true);
    },
    [form]
  );
  const onDividerFieldChange = useCallback(
    (value: string, name: number, field: keyof TOfferPositionFromValue) => {
      form.setFieldValue(
        ["positions", name, field],
        value?.replaceAll(".", ",")
      );
    },
    [form]
  );
  const onPositionFromApplicationSelect = useCallback(
    (value: string, name: number) => {
      const position = application?.positions?.find(
        (item) => item?.position_id === Number(value)
      );
      form.setFieldValue(
        ["positions", name, "position_name"],
        position?.position_name
      );
      form.setFieldValue(
        ["positions", name, "unit_type_id"],
        position?.unit_type_id
      );
      form.setFieldValue(
        ["positions", name, "category_id"],
        position?.category_id
      );
      form.setFieldValue(
        ["positions", name, "resource_code_id"],
        position?.resource_code_id
      );
      form.setFieldValue(
        ["positions", name, "unit_amount"],
        position?.unit_amount
      );
      form.setFieldValue(["positions", name, "analogue_flag"], !position);
    },
    [application, form]
  );

  const handleDiscountChange = useCallback((value: number | null) => {
    setDiscount(value || 0);
  }, []);
  const clearDiscount = useCallback(() => {
    setDiscount(undefined);
  }, []);

  return (
    <>
      <Form
        name="offer_edit_positions"
        form={form}
        layout="vertical"
        requiredMark={false}
        onFinish={onSubmitHandler}
        autoComplete="off"
        initialValues={initialValues}
      >
        <Form.List
          name="positions"
          rules={[
            {
              validator: async (_, positions) => {
                if (!positions || !positions.length) {
                  return Promise.reject(new Error("Добавьте позиции"));
                }
              }
            }
          ]}
        >
          {(fields, { add, remove }, { errors }) => {
            const columns: TableColumnsType<FormListFieldData> = [
              {
                title: "№",
                key: "index",
                render: (value, record, index) => index + 1,
                minWidth: 64
              },
              {
                title: "Наименование",
                key: "position_name",
                render: ({ name, key }: FormListFieldData) => (
                  <Form.Item
                    key={key}
                    name={[name, "position_name"]}
                    rules={[
                      {
                        required: true,
                        message: "Введите название"
                      }
                    ]}
                  >
                    <Input
                      onChange={() => onClearAuthentity(name)}
                      size="large"
                    />
                  </Form.Item>
                ),
                minWidth: 250,
                className: "withInputs"
              },
              {
                title: "Позиция из заявки",
                key: "application_position_id",
                render: ({ name, key }: FormListFieldData) => (
                  <Form.Item
                    key={key}
                    name={[name, "application_position_id"]}
                    style={{ width: 250 }}
                  >
                    <Select
                      options={positionsOptions}
                      showSearch
                      allowClear
                      onChange={(value) =>
                        onPositionFromApplicationSelect(value, name)
                      }
                      size="large"
                    />
                  </Form.Item>
                ),
                className: "withInputs"
              },
              {
                title: "Товарная категория",
                key: "category_id",
                render: ({ name, key }: FormListFieldData) => (
                  <Form.Item
                    key={key}
                    name={[name, "category_id"]}
                    style={{ width: 250 }}
                    rules={[
                      {
                        required: true,
                        message: "Введите товарную категорию"
                      }
                    ]}
                  >
                    <Select
                      options={applicationPositionCategoryIdOptions}
                      showSearch
                      onChange={() => onClearAuthentity(name)}
                      size="large"
                    />
                  </Form.Item>
                ),
                className: "withInputs"
              },
              {
                title: "Аналог",
                key: "analogue_flag",
                render: ({ name, key }: FormListFieldData) => (
                  <Form.Item
                    key={key}
                    name={[name, "analogue_flag"]}
                    valuePropName="checked"
                  >
                    <Checkbox />
                  </Form.Item>
                ),
                minWidth: 64,
                className: "withInputs"
              },
              {
                title: "Кол-во",
                key: "unit_amount",
                render: ({ name, key }: FormListFieldData) => (
                  <Form.Item
                    key={key}
                    name={[name, "unit_amount"]}
                    rules={[
                      {
                        required: true,
                        message: "Введите кол-во"
                      },
                      {
                        pattern: new RegExp(/^\d*([.,]\d+)?$/),
                        message: "Введите корректное число"
                      }
                    ]}
                  >
                    <Input
                      onChange={(value) =>
                        onDividerFieldChange(
                          value?.target?.value,
                          key,
                          "unit_amount"
                        )
                      }
                      size="large"
                    />
                  </Form.Item>
                ),
                minWidth: 104,
                className: "withInputs"
              },
              {
                title: "Ед. изм.",
                key: "unit_type_id",
                render: ({ name, key }: FormListFieldData) => (
                  <Form.Item
                    key={key}
                    name={[name, "unit_type_id"]}
                    style={{ width: 104 }}
                    rules={[
                      {
                        required: true,
                        message: "Введите ед. изм."
                      }
                    ]}
                  >
                    <Select
                      options={applicationPositionUnitTypeIdOptions}
                      onChange={() => onClearAuthentity(name)}
                      size="large"
                    />
                  </Form.Item>
                ),
                className: "withInputs"
              },
              {
                title: "Цена за единицу, руб",
                key: "unit_price",
                render: ({ name, key }: FormListFieldData) => (
                  <Form.Item
                    key={key}
                    name={[name, "unit_price"]}
                    rules={[
                      {
                        required: true,
                        message: "Введите стоимость"
                      },
                      {
                        pattern: new RegExp(/^\d*([.,]\d{1,2})?$/),
                        message: "Введите корректное число"
                      }
                    ]}
                  >
                    <Input
                      onChange={(value) =>
                        onDividerFieldChange(
                          value?.target?.value,
                          name,
                          "unit_price"
                        )
                      }
                      size="large"
                    />
                  </Form.Item>
                ),
                minWidth: 150,
                className: "withInputs"
              },
              {
                title: "Скидка, %",
                key: "discount",
                render: ({ name, key }: FormListFieldData) => (
                  <Form.Item
                    key={key}
                    name={[name, "discount"]}
                    rules={[
                      {
                        required: true,
                        message: "Введите скидку"
                      },
                      {
                        pattern: new RegExp(/^\d*([.,]\d+)?$/),
                        message: "Введите корректное число"
                      }
                    ]}
                  >
                    <Input
                      onChange={(value) =>
                        onDividerFieldChange(
                          value?.target?.value,
                          name,
                          "discount"
                        )
                      }
                      size="large"
                    />
                  </Form.Item>
                ),
                minWidth: 90,
                className: "withInputs"
              },
              {
                title: "НДС, %",
                key: "vat",
                render: ({ name, key }: FormListFieldData) => (
                  <Form.Item
                    key={key}
                    name={[name, "vat"]}
                    rules={[
                      {
                        required: true,
                        message: "Введите НДС"
                      },
                      {
                        pattern: new RegExp(/^\d*([.,]\d{1,2})?$/),
                        message: "Введите корректное число"
                      }
                    ]}
                  >
                    <Input
                      onChange={(value) =>
                        onDividerFieldChange(value?.target?.value, name, "vat")
                      }
                      size="large"
                    />
                  </Form.Item>
                ),
                minWidth: 90,
                className: "withInputs"
              },
              {
                title: "Комментарий",
                key: "position_comment",
                render: ({ name, key }: FormListFieldData) => (
                  <Form.Item key={key} name={[name, "position_comment"]}>
                    <Input size="large" />
                  </Form.Item>
                ),
                minWidth: 220,
                className: "withInputs"
              },
              {
                title: "Цена, руб",
                key: "price",
                render: ({ key, name }) => (
                  <Form.Item key={key} shouldUpdate>
                    {({ getFieldValue }) => {
                      const amount = getFieldValue([
                        "positions",
                        name,
                        "unit_amount"
                      ]);
                      const price = getFieldValue([
                        "positions",
                        name,
                        "unit_price"
                      ]);
                      const disc = getFieldValue([
                        "positions",
                        name,
                        "discount"
                      ]);
                      const tax = getFieldValue(["positions", name, "vat"]);

                      const total = countPrice(amount, price, disc, tax);
                      return (
                        <Input
                          value={total?.toFixed(2)}
                          variant="borderless"
                          size="large"
                        />
                      );
                    }}
                  </Form.Item>
                ),
                minWidth: 150,
                className: "withInputs"
              },
              {
                key: "remove",
                render: ({ name, key }: FormListFieldData) => (
                  <Button
                    key={key}
                    onClick={() => remove(name)}
                    icon={<MinusOutlined />}
                    size="large"
                  />
                ),
                minWidth: 64
              }
            ];
            const onAddPositionFromApplication = (
              position: TApplicationPosition
            ) => {
              add({
                application_position_id: position?.position_id,
                position_name: position?.position_name,
                unit_amount: position?.unit_amount,
                unit_type_id: position?.unit_type_id,
                category_id: position?.category_id,
                resource_code_id: position?.resource_code_id,
                analogue_flag: false,
                discount: 0,
                vat: 0,
                amount: 0,
                unit_price: 0
              });
            };
            const addAllPositions = () => {
              application?.positions?.forEach(onAddPositionFromApplication);
            };
            const setVAT = ({ key }: { key: string }) => {
              const positions = form
                .getFieldValue("positions")
                ?.map((field: TOfferPositionFromValue[]) => ({
                  ...field,
                  vat: Number(key)
                }));
              form.setFieldValue("positions", positions);
            };
            const applyDiscount = () => {
              const positions = form
                .getFieldValue("positions")
                ?.map((field: TOfferPositionFromValue[]) => ({
                  ...field,
                  discount
                }));
              form.setFieldValue("positions", positions);
              clearDiscount();
            };

            return (
              <>
                <Form.Item>
                  <ApplicationPositionsTable
                    positions={application?.positions}
                    icon={<PlusOutlined />}
                    onIconClick={onAddPositionFromApplication}
                    bestPrices={bestPrices}
                  />
                </Form.Item>

                <Form.Item>
                  <Button
                    onClick={addAllPositions}
                    icon={<OrderedListOutlined />}
                  >
                    Добавить все позиции из заявки
                  </Button>
                </Form.Item>

                <Divider />

                <Form.Item>
                  <TypographyTitleNoMargin level={5}>
                    Позиции предложения
                  </TypographyTitleNoMargin>
                </Form.Item>

                <Form.Item label="НДС">
                  <Dropdown menu={{ items: vatOptions, onClick: setVAT }}>
                    <Button size="large" style={{ width: 400 }}>
                      <Space
                        style={{
                          width: "100%",
                          justifyContent: "space-between"
                        }}
                      >
                        Заполнить НДС
                        <DownOutlined />
                      </Space>
                    </Button>
                  </Dropdown>
                </Form.Item>

                <Form.Item label="Скидка, %">
                  <Space.Compact style={{ width: 400 }}>
                    <InputNumber
                      size="large"
                      style={{ width: "100%" }}
                      min={0}
                      max={100}
                      value={discount}
                      onChange={handleDiscountChange}
                    />
                    <Button size="large" type="primary" onClick={applyDiscount}>
                      Применить
                    </Button>
                  </Space.Compact>
                </Form.Item>

                <Form.Item>
                  <TableWrapper>
                    {!!fields?.length && (
                      <TableScrollWrapper>
                        <Table
                          dataSource={fields}
                          columns={columns}
                          pagination={false}
                        />
                      </TableScrollWrapper>
                    )}

                    <Button
                      onClick={() =>
                        add({
                          analogue_flag: true,
                          discount: 0,
                          vat: 0,
                          amount: 0,
                          unit_price: 0
                        })
                      }
                      icon={<PlusOutlined />}
                      style={{ alignSelf: "flex-start" }}
                    >
                      Добавить позицию
                    </Button>

                    {!fields?.length && (
                      <Empty description="Пока нет ни одной позиции" />
                    )}
                  </TableWrapper>
                </Form.Item>
              </>
            );
          }}
        </Form.List>
      </Form>
      <CTABlock>
        <NavButton type="primary" form="offer_edit_positions" htmlType="submit">
          Далее
        </NavButton>
        <NavButton onClick={onPrevStep}>Назад</NavButton>
      </CTABlock>
    </>
  );
};
