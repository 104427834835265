import { TOfferForSeller } from "types";
import { RequestPromise, apiRequest } from "utils/api";

export const getOffersForSellerByApplicationId = (
  id: TGetOffersForSellerByApplicationIdRequest
): RequestPromise<TGetOffersForSellerByApplicationIdResponse> => {
  return apiRequest.get(`/offers-for-sellers/applications/${id}`);
};

export type TGetOffersForSellerByApplicationIdRequest = number;

export type TGetOffersForSellerByApplicationIdResponse = TOfferForSeller[];
