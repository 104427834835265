import { TLicense } from "types";
import { RequestPromise, apiRequest } from "utils/api";

export const addLicense = (
  data: TAddLicenseRequest
): RequestPromise<TAddLicenseResponse> => {
  return apiRequest.post(`/license/add`, data);
};

export type TAddLicenseRequest = Omit<TLicense, "license_id">;

export type TAddLicenseResponse = TLicense;
