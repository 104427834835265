import React from "react";
import { Routes, Route, useLocation, Navigate } from "react-router-dom";
import ProtectedRoute from "../ProtectedRoute/ProtectedRoute";
import { Admin, NotFound } from "components/pages/old";
import { AuthorizedLayout, UnauthorizedLayout } from "../layouts";
import {
  Applications,
  Approvements,
  EmailPass,
  Help,
  HomePage,
  Login,
  Messages,
  Offers,
  Profile,
  Registration,
  RestorePass
} from "components/pages/redesign";
import { useAppSelector } from "store/store";
import { userSelectors } from "store/user";
import { ECabinetRole } from "types";

export const MainRouter = () => {
  const location = useLocation();

  const { user, currentRole } = useAppSelector(userSelectors.getState);

  return (
    <Routes location={location}>
      <Route
        element={
          <ProtectedRoute>
            <UnauthorizedLayout />
          </ProtectedRoute>
        }
      >
        <Route path="login" element={<Login />} />
        <Route path="registration" element={<Registration />} />
        <Route path="restorepass" element={<RestorePass />} />
        {!user && <Route path="help" element={<Help />} />}
      </Route>

      <Route element={<UnauthorizedLayout />}>
        <Route path="emailpass" element={<EmailPass />} />
      </Route>

      <Route
        element={
          <ProtectedRoute authRequired>
            <AuthorizedLayout />
          </ProtectedRoute>
        }
      >
        <Route index element={<HomePage />} />

        <Route path="profile">
          <Route index element={<Navigate to="personal" replace />} />
          <Route path=":profile_tab/*" element={<Profile />} />
        </Route>

        <Route path="applications/*" element={<Applications />} />
        <Route path="offers/*" element={<Offers />} />

        <Route path="approvements">
          <Route
            index
            element={
              <Navigate
                to={
                  currentRole === ECabinetRole.SELLER
                    ? "offers"
                    : "applications"
                }
                replace
              />
            }
          />
          <Route path=":approvements_tab/*" element={<Approvements />} />
        </Route>

        <Route path="admin">
          <Route index element={<Navigate to="cabinets" replace />} />
          <Route path=":admin_tab/*" element={<Admin />} />
        </Route>

        <Route path="messages/*" element={<Messages />} />

        <Route path="help" element={<Help />} />

        <Route path="*" element={<NotFound />} />
      </Route>
    </Routes>
  );
};
