import { ECabinetRole } from "types";
import { cabinetRoleMapper } from "utils/mappers";

export const cabinetRoleDefaultOptions = [
  ECabinetRole.BUYER,
  ECabinetRole.SELLER
].map((item) => ({
  value: item as ECabinetRole,
  label: cabinetRoleMapper[item as ECabinetRole]
}));
