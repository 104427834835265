import { TProject } from "types";
import { RequestPromise, apiRequest } from "utils/api";

export const updateProject = (
  data: TUpdateProjectRequest
): RequestPromise<TUpdateProjectResponse> => {
  return apiRequest.post(`/project/update`, data);
};

export type TUpdateProjectRequest = TProject;

export type TUpdateProjectResponse = TProject;
