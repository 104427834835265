import { TEntity } from "types";
import { RequestPromise, apiRequest } from "utils/api";

export const addEntity = (
  data: TAddEntityRequest
): RequestPromise<TAddEntityResponse> => {
  return apiRequest.post(`/entities/add`, data);
};

export type TAddEntityRequest = Omit<TEntity, "entity_id">;

export type TAddEntityResponse = TEntity;
