import { TApplication } from "types";
import { RequestPromise, apiRequest } from "utils/api";

export const addApplication = (
  data: TAddApplicationRequest
): RequestPromise<TAddApplicationResponse> => {
  return apiRequest.post(`/applications/add`, data);
};

export type TAddApplicationRequest = Omit<TApplication, "application_id">;

export type TAddApplicationResponse = TApplication;
