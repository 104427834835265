import { TLocation } from "types";
import { RequestPromise, apiRequest } from "utils/api";

export const getLocationsByCabinetId = (
  id: TByCabinetIdLocationsRequest
): RequestPromise<TByCabinetIdLocationsResponse> => {
  return apiRequest.get(`/location/cabinet/${id}`);
};

export type TByCabinetIdLocationsRequest = number;

export type TByCabinetIdLocationsResponse = TLocation[];
