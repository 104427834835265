import { TOfferForBuyer } from "types";
import { RequestPromise, apiRequest } from "utils/api";

export const getOfferForBuyerById = (
  id: TGetOfferForBuyerByIdRequest
): RequestPromise<TGetOfferForBuyerByIdResponse> => {
  return apiRequest.get(`/offers-for-buyers/${id}`);
};

export type TGetOfferForBuyerByIdRequest = number;

export type TGetOfferForBuyerByIdResponse = TOfferForBuyer;
