import styled from "@emotion/styled";
import { DEVICE } from "constants/breakpoints";

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  width: 100%;
  flex: 1;

  @media ${DEVICE.XXL} {
    gap: 16px;
  }
`;
