import { createAsyncThunk } from "@reduxjs/toolkit";
import { applicationForSellerBestPricesByIdActions } from ".";
import * as api from "api/applications_for_sellers";
import { RequestError } from "types";
import { serializeError } from "serialize-error";

export const getApplicationForSellerBestPricesById = createAsyncThunk<
  api.TGetApplicationForSellerBestPricesByIdResponse,
  api.TGetApplicationForSellerBestPricesByIdRequest
>(
  "applicationsForSeller/getApplicationForSellerBestPricesById",
  (payload, { dispatch, rejectWithValue, getState }) => {
    dispatch(applicationForSellerBestPricesByIdActions.setLoading(true));
    dispatch(applicationForSellerBestPricesByIdActions.setError(null));
    return api
      .getApplicationForSellerBestPricesById(payload)
      .then(({ data }) => {
        dispatch(applicationForSellerBestPricesByIdActions.setBestPrices(data));
        return data;
      })
      .catch((err: RequestError) => {
        dispatch(applicationForSellerBestPricesByIdActions.setError(err));
        return rejectWithValue(serializeError(err));
      })
      .finally(() => {
        dispatch(applicationForSellerBestPricesByIdActions.setLoading(false));
      });
  }
);
