import { RequestPromise, apiRequest } from "utils/api";

export const updateOfferForBuyerStatus = (
  data: TUpdateOfferForBuyerStatusRequest
): RequestPromise<TUpdateOfferForBuyerStatusResponse> => {
  return apiRequest.post(`/offers-for-buyers/update_status`, data);
};

export type TUpdateOfferForBuyerStatusRequest = {
  offer_id: number;
  new_status_id: number;
};

export type TUpdateOfferForBuyerStatusResponse = {
  offer_id: number;
  new_status_id: number;
};
