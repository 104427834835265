import { TAccount } from "types";
import { RequestPromise, apiRequest } from "utils/api";

export const deleteAccount = (
  id: TDeleteAccountRequest
): RequestPromise<TDeleteAccountResponse> => {
  return apiRequest.post(`/accounts/delete/${id}`, undefined);
};

export type TDeleteAccountRequest = number;

export type TDeleteAccountResponse = TAccount;
